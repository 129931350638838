.LotsAuction .footer-main-standard {
    padding: 50px 0 0 0;
    color: var(--footerFontColor);
    background: var(--footerColor);
    font-size: 15px;
    border-top: 1px solid #dedede;
}

.LotsAuction .footer-main-standard a {
    color: var(--footerFontColor);
}

.LotsAuction .footer-main-standard .container {
    min-height: auto;
}

.LotsAuction.SBA .footer-main-standard a {
    display: block;
}

.LotsAuction .footer-copy-right {
    padding: 20px 30px 10px;
    background: var(--footerColor);
}

.LotsAuction .footer-copy-right p {
    margin: 0;
}

.LotsAuction .stctPgWrpr a {
    word-break: break-all;
}

.LotsAuction a.cvc-link {
    font-size: 15px;
}

.LotsAuction.localDeals .footHiw {
    display: block !important;
}

.SBA footer .container {
    min-height: 150px;
}

.LotsAuction .footer-main-standard-title {
    margin-bottom: 15px;
    color: var(--primColor);
    font-size: 20px;
    position: relative;
    padding-bottom: 15px;
}

.LotsAuction .footer-main-standard-newsletter {
    position: relative;
    margin-bottom: 20px;
    margin-top: 15px;
}

.LotsAuction .newsletter-wrapper {
    position: relative;
}

.LotsAuction .footer-main-standard-newsletter-input {
    width: 100%;
    border: none;
    border-radius: 45px;
    padding: 13px 26px;
    color: #4a4031;
    font-size: 16px;
    border: 1px solid #ddd;
}

.LotsAuction .footer-main-standard-newsletter-submit {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    height: 52px;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    background: var(--primColor);
    color: #ffffff;
    border: none;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    padding: 0px 20px;
}

.LotsAuction .footer-main-standard-newsletter-submit {
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    transition: 0.2s;
    line-height: 16px;
}

.LotsAuction .footer-main-standard-social-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 0px;
    margin-left: -7px;
    text-align: center !important;
}

.LotsAuction .footer-main-standard-social-list > li {
    display: inline-block;
    margin: 5px 6px;
}

.LotsAuction .footer-main-standard-social-list > li > a {
    display: block;
    text-align: center;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    color: inherit;
    opacity: 0.75;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=75)';
    filter: alpha(opacity=75);
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    transition: 0.3s;
}

.LotsAuction .footer-main-standard-social-list > li > a > svg {
    fill: #fff;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
}

.LotsAuction .ftrDtlCntnr {
    text-align: center;
    display: flex;
    flex-flow: column;
}

.LotsAuction .ftrDtlCntnr .footerLogo {
    width: 150px;
    height: 100%;
    object-fit: contain;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 15px;
}

.LotsAuction .ftrDtlCntnr .cvc-link {
    color: var(--footerFontColor);
    margin: 10px 0;
    display: inline-flex;
    align-items: center;
}

.LotsAuction .ftrDtlCntnr .cvc-link:hover {
    text-decoration: none;
}

.LotsAuction .ftrDtlCntnr .cvc-link .material-icons {
    padding-right: 10px;
    color: var(--footerFontColor);
}

.LotsAuction .ftrDtlCntnr .fa,
.LotsAuction .ftrDtlCntnr .fab,
.LotsAuction .ftrDtlCntnr .fal,
.LotsAuction .ftrDtlCntnr .far,
.LotsAuction .ftrDtlCntnr .fas {
    font-size: 22px;
}

.LotsAuction .footerLinks {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    display: block;
}

.LotsAuction .footerLinks.social-links {
    display: flex;
    justify-content: flex-start;
    width: auto;
    column-gap: 12px;
    align-items: center;
}

.LotsAuction .LotsAuction .footerLinks {
    justify-content: center;
    line-height: 41px;
}

.LotsAuction .footerLinks li:not(:last-child) {
    margin-bottom: 10px;
}

.LotsAuction .footerLinks li a {
    font-weight: 400;
    font-size: 14px;
    word-break: break-all;
}

.LotsAuction .ftrLgCntnr {
    max-width: 140px;
    height: 48px;
    width: 100%;
}

.LotsAuction.SBA .ftrLgCntnr {
    max-width: 150px;
}

.LotsAuction .ftrLgCntnr img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: top left;
}

.LotsAuction .ftrLnkWrpr {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
    max-width: fit-content;
}

.LotsAuction .ftrLnkWrpr .lnkGrdTb:nth-child(3) {
    /* max-width: 800px; */
}

.LotsAuction .lnkGrdTb h4 {
    color: var(--footerFontColor);
    font-weight: 600;
    font-size: 15px;
}

.LotsAuction .lnkGrdTb ul li {
    font-weight: normal;
}

.LotsAuction .adrsFlds p {
    line-height: 27px;
    font-size: 14px;
}

@media (max-width: 991px) {
    .LotsAuction .ftrLgCntnr {
        max-width: 120px;
        height: 65px;
        margin-left: auto;
        margin-right: auto;
    }

    .LotsAuction .ftrWrprVw .twoDivCls {
        width: 100%;
    }

    .LotsAuction .footerLinks {
        display: block;
    }

    .LotsAuction .ftrWrprVw {
        flex-wrap: wrap;
    }

    .LotsAuction .ftrLnkWrpr {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 20px;
    }

    .lgAdCntnr {
        margin-left: auto;
        margin-right: auto;
    }

    .LotsAuction .adrsFlds p {
        line-height: 23px;
        margin-top: 10px;
    }
}

@media (max-width: 600px) {
    .LotsAuction .footer-main-standard .ftrLgCntnr {
        margin-bottom: 15px;
    }
    .LotsAuction .ftrDtlCntnr .footerLogo {
        width: 100px;
    }

    .LotsAuction .ftrDtlCntnr {
        margin-bottom: 20px !important;
    }

    .LotsAuction .MultiSeller .ftrDtlCntnr {
        justify-content: center;
    }
}

@media (max-width: 540px) {
    .LotsAuction .ftrLnkWrpr {
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    .LotsAuction .ftrLnkWrpr .lnkGrdTb:nth-last-child(1) {
        grid-column: span 2;
    }
}

.LotsAuction .adrsFlds p.copyright {
    line-height: 18px;
    font-size: 12px;
    max-width: 206px;
    margin: 5px 0 30px;
}

@media (max-width: 767px) {
    .LotsAuction .adrsFlds p.copyright {
        font-size: 14px;
        margin: 15px 0;
        max-width: 100%;
    }

    .LotsAuction .footerLinks.social-links {
        justify-content: center;
    }
}

@media (max-width: 360px) {
    .LotsAuction .ftrLnkWrpr {
        grid-template-columns: 100%;
    }
}
