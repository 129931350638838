.search.siteBanner {
    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0;
}

.search.siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.search.siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.filters-by,
.sort-by {
    margin: 0 -5px;
}

.filters-by .filter-item,
.sort-by .sort-item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

.filters-by .custom-select {
    background: #f9f9f9
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid #ececec;
}

.sort-by .custom-select {
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid transparent;
}

.sort-item a {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.sort-item a:hover,
.sort-item a:focus,
.sort-item a.active {
    color: #ba122b;
}

.search-wrapper {
    margin-bottom: 30px;
}

.auctionListCnt {
    position: relative;
    margin-top: 0;
    background: #fff;
    padding: 30px;
    border-radius: 20px;
}

.auctionListCnt .auctionListTitle {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #656565;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
}

.auctionListCnt .auctionListTitle::before {
    position: absolute;
    content: '';
    width: 30%;
    height: 1px;
    background: #dedede;
    left: 10%;
    top: 10px;
}

.auctionListCnt .auctionListTitle::after {
    position: absolute;
    content: '';
    width: 30%;
    height: 1px;
    background: #dedede;
    right: 10%;
    top: 10px;
}

.auctionGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.liquidationTwo .auctionsSearch .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.liquidationTwo .auctionsSearch .searchResults.Grid .card-body .primButton button {
    border-radius: 12px;
}

.liquidationTwo .auctionsSearch .searchResults.Grid .list-title {
    text-align: center;
    height: 7ex;
}

.liquidationTwo .auctionsSearch .searchResults.Grid .li-timer-wrap {
    height: 68px;
}

.liquidationTwo .auctionsSearch .auctionListTitle {
    font-size: 26px;
    color: #2e2e2e;
    font-weight: 500;
    margin: 0 0 15px;
}

.liquidationTwo .auctionsSearch .grid-card .grid-img-wrap img {
    height: 260px;
    object-fit: cover;
}

.liquidationTwo .liquidationBuyCard .grid-card .grid-img-wrap img {
    object-fit: scale-down;
}

.liquidationTwo .grid-card .grid-img-wrap img {
    cursor: pointer;
}

.liquidationTwo .auctionsMiscFilter {
    display: flex;
    margin-left: auto;
}

.BidAuction .auctionsMiscFilter {
    align-items: center;
    gap: 15px;
}

.BidAuction .auctionsMiscFilter .depotFilterBtns {
    border: 0;
    width: fit-content;
    color: #49454f;
    font-size: 14px;
    padding: 0;
    font-weight: 400;
}

.liquidationTwo .auctionSearchPaginationTop .customCheckbox .MuiListItem-button {
    padding-right: 0;
    padding-left: 0;
}

.liquidationTwo .auctionsMiscFilter .customSelect {
    margin-left: 25px;
    margin-bottom: 0;
}

.BidAuction .auctionsMiscFilter .customSelect .MuiSelect-select {
    background: #ffffff;
}

.liquidationTwo .auctionSearchPaginationTop .customCheckbox .MuiFormControlLabel-root {
    margin: 0px auto;
    margin-top: 8px;
}

.liquidationTwo.emursMck .auctionsMiscFilter .gridListToggle button span {
    opacity: 0.7;
}

.liquidationTwo.emursMck .auctionsMiscFilter .gridListToggle button.active span {
    opacity: 1;
}

.liquidationTwo .auctionsMiscFilter .gridListToggle button {
    min-width: initial;
    color: #707070;
}

.liquidationTwo .auctionsMiscFilter .gridListToggle button.active {
    color: var(--primColor);
}

.BidAuction .auctionsMiscFilter .gridListToggle button.active {
    color: #ff6503;
}

.BidAuction .auctionsMiscFilter .gridListToggle button .MuiButton-label {
    gap: 5px;
    align-items: center;
}

@media (min-width: 992px) {
    .nav-sticky .nav-to-sticky {
        z-index: 11;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
    }

    .nav-sticky .search-category-box {
        position: fixed !important;
        border: none;
        background: #fff;
        z-index: 9;
        width: 100%;
        animation: navSticky 0.5s;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        left: 0;
        padding: 10px 15px;
        top: 50px;
    }

    .nav-sticky {
        padding-top: 124px;
    }
}

@media (max-width: 991px) {
    .liquidationTwo .gridListToggle {
        position: inherit;
        right: 0;
        top: 0;
        left: auto;
    }
}

@media (max-width: 767px) {
    .liquidationTwo .auctionsMiscFilter .customSelect {
        margin-left: 5px;
    }

    .siteBanner h1 {
        width: max-content;
        text-align: center;
        font-size: 28px;
    }

    .siteBanner {
        height: 300px;
    }

    .liquidationTwo .auctionsSearch .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 20%;
        left: 0;
    }

    .liquidationTwo .auctionsSearch .auctionSearchCnt {
        margin-bottom: 0 !important;
    }
}

@media (max-width: 700px) {
    .liquidationTwo .auctionsMiscFilter {
        display: flex;
        width: 100%;
        margin: 15px auto;
    }

    .liquidationTwo .auctionListTitle {
        text-align: center;
        display: block;
        width: 100%;
        font-size: 18px;
        text-align: left !important;
    }

    .liquidationTwo .auctionsMiscFilter .customSelect {
        width: 100%;
    }

    .liquidationTwo .toggleRespDrawer.MuiButton-root {
        margin-bottom: 0;
    }
}

@media (max-width: 600px) {
    .liquidationTwo .auctionsSearch .searchResults.Grid .list-title {
        min-height: 6ex;
        height: 100%;
    }

    .liquidationTwo .gridListToggle {
        width: 100%;
        text-align: right;
    }

    .liquidationTwo .auctionsMiscFilter {
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-bottom: 0;
    }

    .liquidationTwo.hyperMicro .auctionsMiscFilter {
        flex-wrap: wrap;
    }

    .liquidationTwo .auctionsMiscFilter .customSelect {
        width: 48%;
        margin-left: 5px;
    }

    /* .liquidationTwo.hyperMicro .auctionsMiscFilter .customSelect {
        width: calc(100% - 70%);
        margin-left: 5px;
    } */
    .gridListToggle {
        /* right: 25px; */
    }

    .liquidationTwo .auctionsSearch .grid-card .grid-img-wrap img {
        height: 200px;
    }

    .liquidationTwo .gridContainer {
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    }

    .liquidationTwo .auctionsSearch .auctionListTitle {
        font-size: 20px;
        text-align: center !important;
    }
}

@media (max-width: 450px) {
    .gridListToggle {
        /* right: 5px; */
    }

    .siteBanner {
        height: 300px;
        top: 40%;
    }

    .siteBanner h1 {
        font-size: 18px;
        letter-spacing: 3px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 15%;
    }
}

@media (max-width: 425px) {
    .liquidationTwo .auctionsMiscFilter .customSelect {
        width: 100%;
        margin-bottom: 15px;
    }
}
