.LotsAuction .home {
    margin-bottom: 0;
    padding-bottom: 0;
}

.LotsAuction .footer-main-standard {
    margin-top: 0;
}

.LotsAuction .siteBanner {
    width: 100%;
    height: 550px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    border-radius: 0;
    background: #fff7f1;
}

.LotsAuction .siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
}

.SBA .siteBanner img {
    object-fit: scale-down;
    object-position: center;
}

.LotsAuction .siteBanner img {
    border-radius: 24px;
}

/* .siteBanner {
    height: 450px;
    background: #fff;
} */

.siteBanner-absent {
    height: 200px;
}

.siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.filters-by,
.sort-by {
    margin: 0 -5px;
}

.filters-by .filter-item,
.sort-by .sort-item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

.filters-by .custom-select {
    background: #f9f9f9
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid #ececec;
}

.sort-by .custom-select {
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid transparent;
}

.sort-item a {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.sort-item a:hover,
.sort-item a:focus,
.sort-item a.active {
    color: #ba122b;
}

.search-wrapper {
    margin-bottom: 30px;
}

.LotsAuction .home .auctionListCnt {
    position: relative;
    background: #fff7f1;
    padding: 0 15px;
    border-radius: 20px;
    max-width: 1310px;
    padding-top: 25px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.LotsAuction .home .auctionListCnt .search-wrapper:first-child .auctionListTitle {
    margin-top: 0 !important;
}

.LotsAuction .home .auctionListCnt .auctionListTitle {
    margin: 45px 0 15px 0 !important;
    font-size: 24px;
    font-weight: 600;
    text-transform: initial;
    letter-spacing: 0;
    color: #434343;
    text-align: left;
    position: relative;
}

.LotsAuction .home .auctionListCnt .search-wrapper .viewAll {
    color: #875486;
    font-weight: 600;
    height: 45px;
    margin: 15px auto;
    display: flex;
}

.twoCart .buynow .li-timer-wrap {
    min-height: 83px;
}

.auctionListCnt .auctionListTitle::before {
    display: none;
}

.auctionListCnt .auctionListTitle::after {
    display: none;
}

.LotsAuction .home .auctionListCnt .auctionListTitle.mobiusMart {
    margin: 0px 0px 15px 0px !important;
}

.LotsAuction .mobiusMartCard .card-body .primButton {
    margin-top: 35px;
}

.LotsAuction .mobiusMartCard .card-body .gaInfo label {
    display: none;
}

.auctionGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.LotsAuction .home .siteBanner .bannerContent {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 240px;
    text-align: center;
}

.LotsAuction .home .siteBanner .bannerContent h2 {
    font-size: 55px;
    margin-bottom: 20px;
    color: var(--bannerTextColor);
    font-weight: 700;
    letter-spacing: 1px;
}

.LotsAuction .home .siteBanner .bannerContent p {
    font-size: 15px;
    color: #333;
    width: 100%;
    max-width: 235px;
    min-height: 56px;
    margin: 0 auto;
}

.LotsAuction .home .siteBanner .bannerContent button {
    border-radius: 8px;
    min-width: 177px;
    width: max-content;
    max-width: 210px;
    height: 44px;
}

.LotsAuction .home .siteBanner .bannerContent button .MuiButton-label {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.banner-btns-wrap {
    display: flex;
    justify-content: center;
    column-gap: 15px;
}

.homeActiveAuction {
    background: #efefef;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.homeActiveAuction h6 {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--primColor);
}

.homeActiveAuction h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #2f2f2f;
}

.homeActiveAuction .primButton button {
    width: 180px;
    border-radius: 25px;
}

.noContent {
    text-align: center;
}

.noContent h3 {
    font-size: 20px;
    color: #262626;
}

.twoCart {
    display: grid;
    grid-template-columns: auto 300px;
    gap: 15px;
}

.auctionListCnt .loadingCnt {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.auctionListCnt .loadingCnt .skeletonWrapper {
    box-shadow: none;
}

.LotsAuction .siteBanner.multiple .slick-dots {
    bottom: 125px;
    background: #ffffff6e;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-radius: 100px;
    padding-top: 3px;
}

.why-choose,
.we-at-wrap {
    background: #fff7f1;
    padding: 55px;
}

.LotsAuction .filterAcc .MuiPaper-root {
    background-color: transparent;
}

.LotsAuction .filterAcc .MuiPaper-root.col-12 {
    background-color: transparent;
    padding: 0;
}

.wc-head {
    font-size: 32px;
    line-height: 40px;
    color: #333;
    text-align: center;
    margin-bottom: 45px;
    font-weight: 600;
}

.wc-card {
    padding: 40px;
    background: #fff;
    text-align: center;
    border: 1px solid #f2f2f2;
    border-radius: 15px;
}

.wc-card-img {
    height: 190px;
    object-fit: contain;
}

.wc-card-head {
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
    color: #333;
    margin: 15px auto;
}

.wc-card-descp {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: #333;
    max-width: 285px;
    margin: 0 auto;
}

.LotsAuction .home .siteBanner {
    height: 313px;
    max-width: 1280px;
    margin: 18px auto 42px;
    margin-bottom: 0;
}

.sc-img-box {
    width: 194px;
    height: 194px;
    border: 1px solid #edeaea;
    border-radius: 16px;
    padding: 34px 32px;
    margin: 20px 0 0 0;
    background-color: #ffffff;
    overflow: hidden;
}

.sc-img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.shop-by-categories .slick-slider .slick-slide .sc-card {
    max-width: calc(100% - 20px);
}

.shop-by-categories .sc-label {
    margin: 10px auto;
    text-align: center;
    font-size: 13px;
    line-height: 1;
    color: #000;
}

.we-at-card {
    width: 100%;
    max-width: 394px;
    margin: 20px auto;
    text-align: center;
}

.LotsAuction .we-at-card {
    display: flex;
    height: 100%;
    width: 100%;
    border: 0;
    box-shadow: 0px 1px 3px 0px #26262640;
    border-radius: 15px;
    background: white;
    padding: 15px;
    max-width: 100%;
    margin: 0;
}

.LotsAuction .we-at-card img {
    width: 100%;
    max-width: 240px;
    height: 100%;
}

.LotsAuction .we-at-card h4 {
    margin-top: 0;
}

.LotsAuction .we-at-card div.loc {
    width: 100%;
    padding-left: 20px;
    text-align: left;
}

.we-at-wrap {
    margin: 45px auto;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
}

.we-at-card-head {
    font-size: 24px;
    font-weight: 600;
    margin: 20px 0 10px;
}

.we-at-card-descp {
    font-size: 16px;
    line-height: 24px;
}

.we-at-card .primButton button {
    height: 50px;
    margin: 0 auto;
    border-radius: 8px;
    max-width: 144px;
    font-size: 18px;
    text-transform: uppercase !important;
}

.LotsAuction .home .auctionListCnt .auctionListTitle {
    position: relative;
}

.home-fa-filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
}

.LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters ul li:nth-child(1) button {
    width: auto;
    min-width: 50px;
}

.LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters .viewAll {
    width: 144px;
    font-weight: 600;
    height: 48px;
    margin: 0;
    display: flex;
    background: #f1f6ff;
    color: var(--primColor);
}

.LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters .dateFilter {
    color: var(--primColor);
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
}

.LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters .active {
    font-weight: 500;
    font-size: 13px;
    height: 36px;
    width: 160px;
    margin: 0;
    display: flex;
    color: #fff;
    border-radius: 100px;
    text-transform: capitalize;
    background: var(--secColor);
}

.LotsAuction
    .home
    .auctionListCnt
    .search-wrapper
    .home-fa-filters
    .selectDate
    .MuiIconButton-label {
    font-size: 16px;
}

.LotsAuction
    .home
    .auctionListCnt
    .search-wrapper
    .home-fa-filters
    .selectDate
    .MuiButtonBase-root {
    padding: 6px 8px;
    border-radius: 0;
}

.LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters .selectDate .MuiInputBase-root {
    height: 35px;
}

.we-at-wrap .container {
    max-width: 940px;
}

.new-auction {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    height: 40px;
    background: var(--secColor);
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.01px;
    padding: 12px;
    border-radius: 0 0 16px 16px;
}

.new-auction a {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
}

.LotsAuction .loa-home-wrapper .gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    grid-gap: 0;
    gap: 0;
}

.LotsAuction .loa-home-wrapper .gridContainer .slick-arrow {
    background: #ffffff;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    color: #000;
    box-shadow: 0px 0px 4px -2px #000;
    top: 43%;
    z-index: 9;
}

.LotsAuction .loa-home-wrapper .gridContainer .slick-arrow.slick-prev {
    left: -35px;
    right: auto;
}

.LotsAuction .loa-home-wrapper .gridContainer .slick-arrow.slick-prev::before {
    content: 'arrow_back_ios';
    /* Material Icons name */
    font-family: 'Material Icons';
    color: #000;
    /* Change arrow color */
    font-size: 24px;
    /* Change arrow size */
    line-height: 1;
    /* Adjust if needed */
}

.LotsAuction .loa-home-wrapper .gridContainer .slick-arrow.slick-next::before {
    content: 'arrow_forward_ios';
    /* Material Icons name */
    font-family: 'Material Icons';
    color: #000;
    /* Change arrow color */
    font-size: 24px;
    /* Change arrow size */
    line-height: 1;
    /* Adjust if needed */
}

.LotsAuction .loa-home-wrapper .gridContainer .slick-initialized .slick-slide {
    display: block;
    padding: 6px 23px;
}

.LotsAuction .loa-home-wrapper .gridContainer .slick-track {
    margin: 0 -20px;
}

.LotsAuction .loa-home-wrapper .liquidationAuctions .grid-img-wrap {
    display: grid;
    grid-template-columns: 48% 1fr;
    grid-gap: 10px;
    gap: 10px;
}

.LotsAuction .loa-home-wrapper .grid-card .grid-img-wrap img {
    height: 117px;
    object-fit: scale-down;
}

.LotsAuction .loa-home-wrapper.featuredLots .grid-card .grid-img-wrap img {
    height: 250px;
    object-fit: scale-down;
}

.LotsAuction .loa-home-wrapper .liquidationAuctions .grid-img-wrap_single {
    grid-template-columns: 100% !important;
}

.LotsAuction .loa-home-wrapper .grid-card .grid-img-wrap_single img {
    height: 315px !important;
}

.home-fa-filters ul {
    display: flex;
    align-items: center;
    list-style: none;
    column-gap: 5px;
    flex-wrap: wrap;
}

.home-fa-filters ul li a {
    padding: 10px 24px;
    line-height: 16px;
    font-size: 14px;
    color: var(--primColor) !important;
    border-radius: 16px;
    min-width: 125px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}

.home-fa-filters ul li a.active,
.home-fa-filters ul li a:hover,
.home-fa-filters ul li a:focus {
    background: var(--secColor) !important;
    color: #fff !important;
}

.home-fa-filters .MuiFormControl-root.customDatepicker {
    margin-bottom: 0;
    max-width: 285px;
}

.home-fa-filters ul li .MuiOutlinedInput-input {
    padding: 14px;
    color: var(--primColor);
}

.home-fa-filters ul li .MuiOutlinedInput-notchedOutline {
    border: none;
}

.home-fa-filters ul li .MuiIconButton-root {
    color: var(--primColor);
}

.LotsAuction .slick-next:before,
.LotsAuction .slick-prev:before {
    font-size: 30px;
    line-height: 1;
    opacity: 1;
    color: var(--primColor);
}

.LotsAuction .slick-next.slick-disabled:before,
.LotsAuction .slick-prev.slick-disabled:before {
    opacity: 0.75;
    cursor: not-allowed;
}

.LotsAuction .upcomming-sliebar .upcomingLots .slick-next:before,
.LotsAuction .slick-next,
.LotsAuction .upcomming-sliebar .upcomingLots .slick-prev:before,
.LotsAuction .slick-prev {
    background: transparent;
}

.LotsAuction .loa-home-wrapper .liquidationAuctions.featuredLots .grid-img-wrap {
    grid-template-columns: 1fr !important;
}

.LotsAuction .loa-home-wrapper .liquidationAuctions .card-body {
    padding: 28px 10px;
    padding-bottom: 19px;
}

@media (min-width: 992px) and (max-width: 1366px) {
    .twoCart .gridContainer {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
}

@media (min-width: 992px) {
    .nav-sticky .nav-to-sticky {
        z-index: 11;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
    }

    .liquidationAuctions .grid-card.buynow {
        max-width: 302px;
        min-width: 302px;
    }

    .LotsAuction .liquidationAuctions .grid-card {
        padding: 0px;
    }

    .LotsAuction .loa-home-wrapper .liquidationAuctions .grid-img-wrap {
        background-color: #f3f7ff;
        padding: 25px 16px;
    }

    .LotsAuction .loa-home-wrapper .liquidationAuctions .grid-img-wrap .qurantHref {
        background-color: #ffffff;
        padding: 15px;
    }

    .LotsAuction .loa-home-wrapper .liquidationAuctions .card-body {
        padding: 28px 10px;
        padding-bottom: 19px;
    }

    .LotsAuction .liquidationAuctions .grid-card.buynow {
        max-width: none;
        min-width: 0;
    }

    .nav-sticky .search-category-box {
        position: fixed !important;
        border: none;
        background: #fff;
        z-index: 9;
        width: 100%;
        animation: navSticky 0.5s;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        left: 0;
        padding: 10px 15px;
        top: 50px;
    }

    .nav-sticky {
        padding-top: 124px;
    }
}

@media (max-width: 992px) {
    .twoCart {
        display: flex;
        flex-direction: column;
    }

    .LotsAuction .we-at-card {
        flex-direction: column;
    }

    .LotsAuction .we-at-card div.loc {
        text-align: center;
        padding-left: 0;
    }

    .LotsAuction .we-at-card img {
        width: 100%;
        height: 100%;
        max-width: 100%;
    }

    .LotsAuction .we-at-wrap .col-sm-6 {
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .wc-head {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .we-at-card .we-at-card-img {
        max-width: 100%;
    }

    .sc-img-box {
        padding: 15px;
        height: 125px;
        width: 100%;
    }

    .siteBanner h1 {
        width: max-content;
        text-align: center;
        font-size: 28px;
    }

    .siteBanner {
        height: 300px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 20%;
        left: 0;
    }

    .LotsAuction .home .siteBanner .bannerContent {
        top: 15px;
        left: 0;
        max-width: calc(100% - 30px);
        right: 0;
        margin: auto;
    }

    .LotsAuction .home .siteBanner .bannerContent h2 {
        font-size: 25px;
    }

    .LotsAuction .home .siteBanner .bannerContent p {
        font-size: 15px;
    }

    .LotsAuction .home .auctionListCnt {
        margin-top: -15px;
    }

    .LotsAuction .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }

    .LotsAuction .home .siteBanner {
        padding: 0 15px;
    }

    .LotsAuction .home .siteBanner .banner-item {
        height: 350px;
    }

    .LotsAuction .siteBanner img {
        object-position: center;
    }

    .why-choose,
    .we-at-wrap {
        padding: 25px 15px;
    }

    .wc-card {
        padding: 15px 15px 15px 165px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 165px;
        margin-top: 20px;
    }

    .wc-card-head,
    .wc-card-descp {
        width: 100%;
        max-width: 100%;
        text-align: left;
    }

    .wc-card-head {
        margin: 0 0 15px;
    }

    .wc-card-img {
        height: 125px;
        width: 125px;
        position: absolute;
        top: 15px;
        left: 15px;
    }

    .LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters {
        flex-wrap: wrap;
    }

    .LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters li {
        width: 32%;
    }

    .LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters li:last-child {
        min-width: 33%;
        width: 100%;
    }

    .LotsAuction
        .home
        .auctionListCnt
        .search-wrapper
        .home-fa-filters
        .selectDate
        .MuiInputBase-root {
        padding: 0;
    }

    .LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters .active,
    .LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters .dateFilter {
        width: auto;
        padding: 0 5px;
        font-size: 12px;
        line-height: 1.25;
        min-width: auto;
    }

    .LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters .dateFilter {
        justify-content: flex-start;
    }

    .home-fa-filters ul {
        grid-column-gap: 0;
        column-gap: 0;
        justify-content: flex-start;
    }

    .LotsAuction
        .home
        .auctionListCnt
        .search-wrapper
        .home-fa-filters
        .selectDate
        .MuiIconButton-label,
    .LotsAuction .home-fa-filters ul li .MuiOutlinedInput-input {
        font-size: 12px;
    }

    .LotsAuction
        .home
        .auctionListCnt
        .search-wrapper
        .home-fa-filters
        .selectDate
        .MuiButtonBase-root {
        padding: 0;
        border-radius: 0;
        width: 100%;
    }

    .home-fa-filters ul li .MuiOutlinedInput-input {
        padding: 0;
    }

    .LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters .viewAll {
        margin: 20px auto;
    }

    .LotsAuction .loa-home-wrapper .gridContainer .slick-arrow {
        width: 30px;
        height: 30px;
        top: 50%;
    }

    .LotsAuction .loa-home-wrapper .gridContainer .slick-arrow.slick-next {
        right: -11px;
    }

    .LotsAuction .loa-home-wrapper .gridContainer .slick-arrow:before {
        font-size: 18px;
    }

    .LotsAuction .loa-home-wrapper .gridContainer .slick-arrow.slick-prev::before {
        font-size: 18px;
        width: 12px;
        margin: auto;
        display: block;
    }

    .LotsAuction .loa-home-wrapper .gridContainer .slick-arrow.slick-prev {
        left: -11px;
    }
}

@media (max-width: 600px) {
    .LotsAuction .home .siteBanner .bannerContent p {
        font-size: 14px;
    }

    .LotsAuction .home .siteBanner .bannerContent h2 {
        font-size: 20px;
    }

    .LotsAuction .new-auction {
        font-size: 13px;
    }

    .LotsAuction .siteBanner.multiple .slick-dots {
        bottom: 60px;
    }
}

@media (max-width: 479px) {
    .LotsAuction .home .auctionListCnt .search-wrapper .home-fa-filters li {
        width: 50%;
        margin-bottom: 5px;
    }

    .wc-head {
        font-size: 18px;
    }

    .wc-card {
        padding: 15px;
    }

    .LotsAuction .loa-home-wrapper .grid-card .grid-img-wrap img {
        height: 90px;
    }

    .wc-card-img {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 auto 20px;
    }

    .wc-card-head,
    .wc-card-descp {
        line-height: 1.25;
        text-align: center;
    }

    .LotsAuction .loa-home-wrapper .gridContainer .slick-initialized .slick-slide {
        padding: 0;
    }

    .LotsAuction .loa-home-wrapper .gridContainer .slick-track {
        margin: 0;
    }

    .LotsAuction .home .siteBanner .bannerContent button {
        min-width: 120px;
    }

    .LotsAuction .mobiusMartCard .card-body .primButton {
        margin-top: 20px;
    }

    .siteBanner {
        height: 250px;
        top: 40%;
    }

    .LotsAuction .home .auctionListCnt {
        margin-top: 0;
    }

    .siteBanner h1 {
        font-size: 18px;
        letter-spacing: 3px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 15%;
    }

    .LotsAuction .home {
        background: #fff;
    }

    .new-auction {
        height: auto;
        line-height: 1.25;
    }
}
