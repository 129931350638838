.overlay.newsOverlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.popup.newsletterpop {
    background: #fff;
    padding: 20px 50px;
    border-radius: 10px;
    width: 600px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
}
.popup.newsletterpop .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
}
.popup.newsletterpop .icon {
    font-size: 30px;
    background: #203c7d;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    margin-bottom: 10px;
    position: absolute;
    width: 70px;
    height: 70px;
    bottom: auto;
    right: 0;
    left: 0;
    margin: auto;
    top: -32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup.newsletterpop .icon span.material-icons {
    font-size: 32px;
}
.popup.newsletterpop .title {
    color: #203c7d;
    font-size: 32px;
    font-weight: bold;
    margin-top: 40px;
}
.popup.newsletterpop .subtitle {
    color: gray;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
}
.popup.newsletterpop .input-container {
    display: flex;
    overflow: hidden;
}
.popup.newsletterpop .input-container input {
    flex: 1;
    padding: 10px;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
}
.popup.newsletterpop .input-container button {
    background: #203c7d;
    color: #fff;
    border: none;
    padding: 10px 25px;
    cursor: pointer;
    margin-left: 15px;
    border-radius: 5px;
}
.popup.newsletterpop .footer-text {
    font-size: 19px;
    color: gray;
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 0;
}
@media (max-width: 450px) {
    .popup.newsletterpop {
        margin: 0px 20px;
        padding: 20px;
    }
    .popup.newsletterpop .input-container {
        flex-direction: column;
        gap: 5px;
    }
    .popup.newsletterpop .input-container button {
        margin-left: 0px;
    }
}
