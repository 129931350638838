.LotsAuction .vcInvoice {
    padding: 2rem !important;
}
.LotsAuction .vcInvoice .invLogo {
    width: 150px;
}
.LotsAuction .vcInvoice .right {
    text-align: right !important;
}
.LotsAuction .vcInvTot span {
    font-weight: 500;
}

.LotsAuction .invoiceCard {
    margin-bottom: 30px;
    border: none;
    -webkit-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    -moz-box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
    box-shadow: 0px 1px 2px 1px rgba(154, 154, 204, 0.22);
}

.LotsAuction .card-header {
    background-color: #fff;
    border-bottom: 1px solid #e6e6f2;
}

.LotsAuction .invoiceCard h3 {
    font-size: 20px;
}
.LotsAuction .invoiceTitle {
    font-size: 18px;
    margin: 35px 0 15px 0;
    font-weight: 600;
    color: #3a3a3a;
}
.vcInvoice .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(241, 241, 241);
}
.LotsAuction .vcInvoice .invoiceCard .table thead th,
.LotsAuction .vcInvoice .invoiceCard .table tbody td {
    font-weight: 500;
    font-size: 13px;
    padding: 8px 5px;
}
.LotsAuction .vcInvoice .invoiceCard .table tbody td {
    font-weight: 400;
}

.LotsAuction .vcInvoice .invoiceCard .table tbody td .nowrap {
    white-space: normal;
    max-width: 400px;
}
.invoiceCard h5 {
    font-size: 15px;
    line-height: 26px;
    color: #3d405c;
    margin: 0px 0px 15px 0px;
}

.text-dark {
    color: #3d405c !important;
}
.transDet .invDiv {
    /* width: 25%; */
    margin-bottom: 15px;
}
.transDet p {
    font-size: 15px;
    margin-bottom: 0px;
    color: #5f5f5f;
}
.transDet span {
    width: 100%;
    font-weight: 500;
}
