.list-item-wrapper {
    background: transparent;
    border: none;
    margin: 40px auto 0;
}

.item-title {
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    text-align: left;
    color: #2e2e2e;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.details-link {
    font-weight: 500;
    text-decoration: underline;
    font-size: 13px;
    line-height: 18px;
    text-align: left;
    color: #2e2e2e;
    margin-bottom: 8px;
}

.bidGroupBtn form .primButton {
    margin-left: 5px;
    min-width: 132px;
}

.bidGroupBtn form .primButton .MuiButtonBase-root {
    height: 40px !important;
}

.bidGroupBtn form .customInput {
    margin-bottom: 0;
}

.loc-name {
    font-family: Roboto;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: left;
    color: #3d3d3d;
    margin-bottom: 8px;
}

.item-text {
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #3d3d3d;
    margin-bottom: 8px;
}

.item-text.small-txt {
    font-size: 12px;
}

.list-item-wrapper .media-left {
    width: 300px;
    margin-right: 15px;
}

.list-item-wrapper .media-left img {
    display: block;
    width: 100%;
    height: 284px;
    object-position: center;
    object-fit: cover;
    border-radius: 5px;
}

.grid-card {
    width: 100%;
    background: transparent;
    border: none;
    /* margin: 30px auto 0; */
}

.biddingCnt.notLogged .primButton,
.biddingCnt.notLogged .primButton button {
    width: 100%;
    max-width: initial !important;
    border-radius: 4px;
}

.biddingCnt.notLogged .primButton button {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

.lineItemBidTable .timerCnt > span {
    display: block;
}

.MultiSeller .grid-card {
    box-shadow: 0px 3px 30px #eaeaea;
    padding: 10px;
}

.MultiSeller .lineItemBidTable::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
}

.MultiSeller .lineItemBidTable::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.MultiSeller .lineItemBidTable:hover::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
}

.Liquidation .grid-card .primButton button {
    border-radius: 25px;
    margin: 0;
}

.Liquidation .grid-card .gridItemAct,
.FoodIndustry .grid-card .gridItemAct,
.Auctioneer .grid-card .gridItemAct,
.LiquidationPallet .grid-card .gridItemAct,
.Marine .grid-card .gridItemAct {
    display: flex;
    justify-content: space-between;
}

.FoodIndustry .lineItemBidTable .subLotLine {
    margin: 0 auto;
}

.multisellerListCard .mslDesc .listDescLabel {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
}

.Liquidation .grid-card .gridItemAct .cartBtn,
.Marine .grid-card .gridItemAct .cartBtn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid currentColor;
    color: #92b441;
    min-width: 50px;
    margin: 0 10px;
}

.Liquidation.musicCollect .grid-card .gridItemAct .cartBtn {
    color: #3407b0;
}

.FoodIndustry .grid-card .gridItemAct .cartBtn {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    border: 2px solid currentColor;
    color: var(--primColor);
    min-width: 50px;
    margin: 0 10px;
}

.FoodIndustry .gallery.card.grid-card .card-body .gcTimer,
.LiquidationPallet .gallery.card.grid-card .card-body .gcTimer,
.MultiAuction .gallery.card.grid-card .card-body .gcTimer {
    text-align: left;
}

.MultiAuction .gallery.card.grid-card .card-body .gcTimer {
    margin-bottom: 10px;
}

.FoodIndustry .gallery.card.grid-card .card-body .gcTimer div.closedText {
    min-height: 21.5px;
}

.FoodIndustry .grid-card .gridItemAct .cartBtn.active,
.LiquidationPallet .grid-card .gridItemAct .cartBtn.active,
.Liquidation .grid-card .gridItemAct .cartBtn.active,
.Marine .grid-card .gridItemAct .cartBtn.active {
    color: #fff;
    background: #92b441;
}

.FoodIndustry .grid-card .gridItemAct .primButton,
.LiquidationPallet .grid-card .gridItemAct .primButton,
.Liquidation .grid-card .gridItemAct .primButton,
.Auctioneer .grid-card .gridItemAct .primButton,
.Marine .grid-card .gridItemAct .primButton {
    width: calc(100% - 60px);
}

.LiquidationPallet .grid-card .gridItemAct .cartBtn.Mui-disabled,
.FoodIndustry .grid-card .gridItemAct .cartBtn.Mui-disabled,
.Liquidation .grid-card .gridItemAct .cartBtn.Mui-disabled,
.Marine .grid-card .gridItemAct .cartBtn.Mui-disabled {
    color: #ccc;
}

.LiquidationPallet .grid-card .gridItemAct .favoriteCheck,
.FoodIndustry .grid-card .gridItemAct .favoriteCheck,
.Auctioneer .grid-card .gridItemAct .favoriteCheck,
.Marine .grid-card .gridItemAct .favoriteCheck {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background: #e9e9e9;
}

.FoodIndustry .grid-card .gridItemAct .favoriteCheck,
.LiquidationPallet .grid-card .gridItemAct .favoriteCheck,
.Liquidation .grid-card .gridItemAct .favoriteCheck {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background: #fff;
}

.Liquidation.musicCollect .grid-card .gridItemAct .favoriteCheck {
    margin-left: 0 !important;
}

.Marine .grid-card .gridItemAct .favoriteCheck {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    min-width: 50px;
    background: #fff;
    border: 2px solid #e74e4e;
}

.Auctioneer .grid-card .gridItemAct .favoriteCheck label,
.Liquidation .grid-card .gridItemAct .favoriteCheck label {
    color: var(--primColor);
}

.FoodIndustry .grid-card .gridItemAct .favoriteCheck label,
.LiquidationPallet .grid-card .gridItemAct .favoriteCheck label,
.Marine .grid-card .gridItemAct .favoriteCheck label {
    color: #e74e4e;
}

.FoodIndustry .multiBuyCard .primButton button {
    border-radius: 12px;
}

.FoodIndustry .favoriteCheck input:checked + label,
.LiquidationPallet .favoriteCheck input:checked + label,
.Marine .favoriteCheck input:checked + label {
    color: #e74e4e;
}

.FoodIndustry .grid-card .gridItemAct .favoriteCheck label span,
.Liquidation .grid-card .gridItemAct .favoriteCheck label span {
    font-size: 32px;
}

.grid-card .grid-img-wrap img {
    width: 100%;
    height: 290px;
    object-fit: cover;
    border-radius: 12px;
}

.Liquidation .grid-card .grid-img-wrap img {
    height: 250px;
    border-radius: 8px;
}

.liquidationTwo .grid-card .grid-notes {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    flex-direction: column;
    height: 250px;
    width: 100%;
    background-color: #fde2b5;
    border-radius: 12px;
}

.liquidationTwo .grid-card .grid-notes .primButton button {
    border-radius: 0px !important;
    z-index: 10;
}

.liquidationTwo .grid-card .grid-notes .gaDesc {
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.liquidationTwo .grid-card .img-notes-wrap {
    position: relative;
}

.BidAuction .grid-card .img-notes-wrap {
    height: 250px;
}
.BidAuction .grid-card .galleryPriceCnt .prcTxtVal.text-primary {
    background-color: #458df7;
}
.BidAuction .grid-card .galleryPriceCnt .prcTxtVal.text-success {
    background-color: #51a350;
}
.BidAuction .grid-card .galleryPriceCnt .prcTxtVal.text-danger {
    background-color: #d7542d;
}
.BidAuction .grid-card .galleryPriceCnt .prcTxtVal.used {
    background-color: #f7cb5f;
    color: #000 !important;
}

.BidAuction .grid-card .galleryPriceCnt .prcTxtVal.text-success,
.BidAuction .grid-card .galleryPriceCnt .prcTxtVal.text-primary,
.BidAuction .grid-card .galleryPriceCnt .prcTxtVal.text-appears,
.BidAuction .grid-card .galleryPriceCnt .prcTxtVal.text-danger,
.BidAuction .grid-card .galleryPriceCnt .prcTxtVal.used {
    color: #fff !important;
    padding: 5px;
    border-radius: 5px;
    padding: 0 6px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.BidAuction .grid-card .galleryPriceCnt .prcTxtVal.used {
    background-color: #f7cb5f;
    color: #000 !important;
}
.liquidationTwo .grid-card .notes-text {
    padding-top: 40px;
    padding-right: 14px;
    padding-left: 14px;
    padding-bottom: 14px;
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
    max-height: calc(100% - 50px);
}

.liquidationTwo .grid-card .fiveStar {
    background-image: url('../../assets/svg/reviewFiveStar.svg');
    height: 250px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.liquidationTwo .grid-card .ratingLiqThree button {
    height: 40px;
}

.liquidationTwo .grid-card .grid-img-wrap.showReview,
.liquidationTwo .grid-card .lctnFavWrapper.showReview,
.liquidationTwo .grid-card .gcTimer.showReview,
.liquidationTwo .grid-card .rtlrPrice.showReview,
.liquidationTwo .grid-card .actnPriceVlue.showReview,
.liquidationTwo .grid-card .condition.showReview,
.liquidationTwo .grid-card .gpBidCart.showReview {
    display: none !important;
}

.liquidationTwo .grid-card .card-body.showReview {
    padding-top: 0px;
    padding-bottom: 12px;
}

.liquidationTwo .grid-card .gvRating {
    cursor: pointer;
}

.liquidationTwo.primeBids .gvRating .MuiRating-root {
    color: #f7d08d;
}

.liquidationTwo.primeBids .gvRating .MuiRating-iconEmpty {
    color: rgb(107, 114, 128);
}

.Liquidation .grid-card .list-title {
    text-align: center;
}

.LotsAuction .grid-card .list-title,
.LotsAuction .multisellerListCard .mslDesc .listTitle {
    font-size: 16px;
    line-height: 24px;
    color: #333;
    text-align: left;
    text-transform: lowercase;
    font-weight: 600;
    font-family: 'Plus Jakarta Sans', sans-serif;
    height: 48px;
    display: block;
    cursor: pointer;
}

.LotsAuction .grid-card .list-title:first-letter,
.LotsAuction .multisellerListCard .mslDesc .listTitle:first-letter {
    text-transform: uppercase;
}

.LotsAuction .multisellerListCard .mslDesc a {
    text-decoration: none;
}

.LotsAuction .lots-details {
    display: flex;
    align-items: center;
    column-gap: 15px;
    font-size: 16px;
    line-height: 24px;
    color: #333;
    font-weight: 500;
}

.LotsAuction .lots-details .pick-up {
    display: flex;
    column-gap: 5px;
    align-items: center;
    color: #828282;
}

.LotsAuction .li-timer-wrap .timerCnt > div,
.LotsAuction .li-timer-wrap .closedText {
    display: flex;
    width: 100%;
    text-align: left !important;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    align-items: center;
    column-gap: 10px;
    margin: 10px 0 0;
    color: #f20505 !important;
    justify-content: flex-start !important;
}

.LotsAuction .li-timer-wrap .timerCnt > div .actualTimer {
    color: #f20505 !important;
}

.Liquidation .grid-card .hostedBy,
.Fundraiser .grid-card .hostedBy {
    display: none;
}

.grid-card .grid-img-wrap img.default {
    object-fit: contain !important;
    background: #f9f9f9;
}

.grid-card .primButton .MuiButton-label i {
    margin-left: 15px;
}

.Auctioneer .grid-card .card-body {
    padding: 12px 15px;
}

/* .LiquidationThreeBuyCard .grid-card .card-body {
    padding: 0 0 12px;
} */
.LiquidationThreeBuyCard .bidHis {
    cursor: pointer;
}

.LiquidationThreeBuyCard .prcTxtVal {
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #000 !important;
}

.LiquidationThreeBuyCard .locationLiq {
    position: absolute;
    border-radius: 25px;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.LiquidationThreeBuyCard .gcTimer {
    color: red;
    font-size: 15px;
    margin: 5px 0px;
}

.LiquidationThreeBuyCard .gcTimer.hoTimer {
    display: none;
}

.LiquidationThreeBuyCard .pickingLiq {
    color: #828282;
    margin-bottom: 0.5rem;
}

.gridNotAvailable {
    color: #b4b4b4;
    font-weight: 500;
    padding-left: 5px;
}

.currBid {
    color: #828282;
    font-size: 13px;
}

.LiquidationThreeBuyCard .currBid {
    color: #828282;
    font-size: 14px;
}

.LiquidationThreeBuyCard button {
    width: fit-content;
    padding: 0px 40px;
}

.price-txt {
    font-weight: 500;
    font-style: italic;
    font-size: 16px;
    text-align: left;
    color: #1e1e24;
    margin: 5px 0;
}

.name-txt {
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    color: #000;
    margin: 5px 0;
    height: 1.4em;
    line-height: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.timer {
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #edbd3d;
    margin: 5px 0;
}

.grid-img-wrap {
    position: relative;
}

.grid-timer {
    width: 100%;
    height: 45px;
    border-radius: 0px 0px 10px 10px;
    background: rgba(0, 0, 0, 0.45);
    position: absolute;
    left: 0;
    bottom: 0;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #fff;
}

.grid-timer img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}

.grid-img-wrap .wl-cbox {
    width: 40px;
    height: 45px;
    border-radius: 0px 10px 0px 0px;
    background: rgba(0, 0, 0, 0.45);
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
}

.grid-img-wrap img.item-img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}

.grid-row {
    margin: 0 -5px 40px;
}

.grid-row .col-md-3 {
    padding: 0 5px;
}

.list-title {
    font-family: var(--primFont);
    font-weight: 600;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 5.5ex;
    text-align: left;
    color: #000;
}

.MultiSeller_two .list-title {
    text-align: center;
}

.MultiSeller_two .grid-card .hostedBy {
    text-align: center;
}

.card .lotCount {
    font-size: 16px;
    color: #fff;
    background: rgba(0, 0, 0, 50%);
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    user-select: none;
}

.card .lotCount .material-icons {
    padding-right: 8px;
}

.sbid-lbl {
    font-weight: 500;
    font-style: italic;
    font-size: 16px;
    color: #000;
}

.sbid-lbl span {
    font-weight: 500;
    font-size: 22px;
    color: #edbd3d;
    margin-left: 15px;
}

.li-timer {
    font-weight: 500;
    font-size: 14px;
    color: #2c2c37;
    margin-bottom: 15px;
}

.lv-timer {
    margin: 30px auto 15px;
}

.list-item-wrapper .media-body {
    padding: 10px 0 10px 25px;
}

.lv-details {
    font-size: 14px;
    text-align: left;
    color: #000;
    margin: 8px 0;
}

.lv-details1 {
    font-size: 14px;
    text-align: left;
    color: #000;
    margin: 12px 0 8px;
}

.lv-details span {
    font-weight: 500;
}

.lv-descp {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-bottom: 8px;
}

.lvd-head {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    margin-bottom: 5px;
}

.auction-status {
    width: 161px;
    height: 35px;
    border-radius: 17.5px;
    background: #e9fff6;
    font-weight: 500;
    font-size: 15px;
    color: #349e74;
    text-align: center;
    padding: 5px;
    margin: 8px 0;
}

.auction-closed-status {
    width: 161px;
    height: 35px;
    border-radius: 17.5px;
    background: #ba122b;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    padding: 5px;
    margin: 8px 0;
}

.liquidationBuyCard .card.grid-card,
.liquidationAuctions .card.grid-card {
    box-shadow: 0px 3px 6px #d3d3d3;
    border-radius: 8px;
    padding: 10px;
}

.Liquidation.SBA .liquidationBuyCard .card.grid-card,
.Liquidation.SBA .liquidationAuctions .card.grid-card {
    height: 100%;
}

.liquidationBuyCard .card.grid-card .react-loading-skeleton {
    width: 100% !important;
}

.liquidationBuyCard .card.grid-card .grid-img-wrap img {
    height: 250px;
    object-fit: cover;
    cursor: pointer;
}

.liquidationBuyCard .list-title {
    text-transform: uppercase;
    font-size: 500;
    margin-bottom: 8px;
}

.liquidationBuyCard .primButton button {
    border-radius: 25px;
}

.MultiAuction .liquidationBuyCard .primButton button {
    border-radius: 5px;
}

.MultiAuction .liquidationBuyCard .customInput {
    margin-bottom: 0;
    margin-right: 5px;
}

.FoodIndustry .Grid.liquidationBuyCard .primButton button {
    border-radius: 12px !important;
}

.FoodIndustry .List.liquidationBuyCard .lineItemBidTable .primButton button {
    border-radius: 12px !important;
}

.FoodIndustry .msSublots .subLotLine .slAct .biddingCnt button {
    border-radius: 0 4px 4px 0 !important;
}

.FoodIndustry .msSublots .subLotLine .slAct .biddingCnt.unAuth button {
    border-radius: 4px !important;
}

.gcTimer {
    font-size: 16px;
    color: #898989;
    font-weight: 500;
    text-align: center;
}

.Liquidation .gcTimer {
    padding: 5px 0;
    font-size: 14px;
    text-align: left;
}

.grid-card .grid-img-wrap .badge.timed {
    background: #e3bb2a;
    color: #333;
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.Liquidation .grid-card .grid-img-wrap .badge.timed,
.hyperMicro .grid-card .grid-img-wrap .badge.timed {
    background-color: #e3bb2a;
}

.grid-card .grid-img-wrap .badge.live {
    background: #e33f3f;
    color: #fff;
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.Liquidation .galleryPrice {
    padding: 5px 0;
    margin-bottom: 0px;
    font-size: 15px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Liquidation .galleryPrice .prcTxtVal {
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    text-align: right;
    word-break: break-word;
}

.Liquidation .noPrice {
    height: 10px;
}

.liquidationTwo .noPrice {
    height: 17px;
}

.MultiSeller_two .name-txt {
    text-align: center;
    margin: 10px 0;
}

.MultiSeller_two .galleryPrice {
    font-size: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.location-class {
    white-space: nowrap !important;
    font-size: 11px !important;
}

/* HeavyEquipment Css */
.nowrap {
    white-space: nowrap;
}

.HeavyEquipment .gallery.card.grid-card .name-txt,
.MultiSeller .gallery.card.grid-card .name-txt {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 5ex;
    color: #000;
    line-height: 22px;
}

.HeavyEquipment .gallery.card.grid-card .galleryPrice,
.MultiSeller .gallery.card.grid-card .galleryPrice {
    font-size: 16px;
    font-weight: 600;
    color: #4e4e4e;
    text-align: center;
    margin: 10px 0;
}

.HeavyEquipment .gallery.card.grid-card .card-body,
.MultiSeller .gallery.card.grid-card .card-body {
    text-align: center;
}

.HeavyEquipment .card-body .biddingCnt .customInput,
.MultiSeller .card-body .biddingCnt .customInput {
    margin: 0;
}

.HeavyEquipment .grid-card {
    width: 100%;
    padding: 11px;
    box-shadow: 0px 0px 6px 4px #0000000f;
}

.HeavyEquipment .Grid .grid-img-wrap:after,
.HeavyEquipment .grid-img-wrap:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(0deg, black, #0000008f, #ffffff00, #ffffff00);
}

.HeavyEquipment .multisellerListCard .grid-img-wrap {
    min-width: 180px;
    max-width: 180px;
}

.HeavyEquipment .grid-img-wrap {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 5px;
}

.HeavyEquipment .grid-card .grid-img-wrap img {
    height: 100%;
}

.HeavyEquipment .gallery.card.grid-card .name-txt {
    text-align: left;
    color: #474747;
    font-size: 20px;
    font-weight: 500;
    height: 150px;
    min-height: fit-content;
    max-height: 44px;
}

.BidAuction .gallery.card.grid-card .name-txt {
    -webkit-line-clamp: 2;
    height: 2.3em;
}

.HeavyEquipment .gallery.card.grid-card .card-body {
    text-align: left;
}

.HeavyEquipment .grid-card .AuctionDateRange span:last-child {
    margin-bottom: 0;
}

.HeavyEquipment .grid-card .listDesc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    color: #707070;
    line-height: 20px;
    font-size: 15px;
    -webkit-box-orient: vertical;
    font-weight: 400;
    min-height: 60px;
}

.HeavyEquipment .gridcardBtn {
    display: flex;
    align-items: center;
}

.HeavyEquipment .gallery.card.grid-card .galleryPrice b small {
    color: #001223;
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    text-decoration: underline;
}

.HeavyEquipment .gallery.card.grid-card .galleryPrice b {
    font-size: 20px;
    font-weight: 600;
    color: var(--primColor);
}

.HeavyEquipment .gallery.card.grid-card .galleryPrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #474747;
    font-weight: 500;
    margin: 16px 0;
}

.HeavyEquipment .gridcardBtn .primButton button {
    height: 40px;
}

.HeavyEquipment .gridcardBtn .material-icons {
    padding-right: 6px;
}

.HeavyEquipment .gridcardBtn .MuiButton-root {
    padding: 3px 10px;
}

.HeavyEquipment .gridcardBtn input::placeholder {
    font-size: 14px;
    font-weight: 400;
}

.HeavyEquipment .grid-card .viewAll span {
    display: none;
}

.HeavyEquipment .grid-card .viewAll {
    margin-bottom: 0;
    color: #474747;
    font-weight: 500;
}

.HeavyEquipment .gridcardBtn input {
    width: 160px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 8px;
    padding: 12px 10px;
}

.HeavyEquipment .grid-card .list-title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.MultiSeller .grid-card .list-title {
    font-size: 18px;
    font-weight: 500;
    color: #2e2e2e;
    text-align: center;
    line-height: 20px;
    height: 5.8ex;
}

.tableTimer {
    white-space: nowrap;
    min-width: 135px;
}

.MultiSeller .grid-card .gcTimer {
    height: 85px;
}

.MultiSeller .grid-card .li-timer {
    margin-bottom: 0;
    min-height: 80px;
}

.MultiSeller .grid-card .biddingCnt > div:first-child {
    max-width: 215px;
}

.MultiSeller .grid-card .card-body .timer-text {
    margin-top: 0 !important;
}

.MultiSeller .grid-card .card-body .primButton button {
    height: 40px;
    margin: 0;
}

.MultiSeller .grid-card .card-body .primButton button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.FoodIndustry .grid-card .card-body .primButton button {
    border-radius: 12px !important;
}

.MultiSeller .grid-card .card-body .customInput .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.MultiSeller .grid-card .li-timer .closedText {
    text-align: center;
    display: block;
    font-weight: 500;
    color: #a2a2a2;
}

.HeavyEquipment .grid-card .gaInfo h6 {
    font-size: 16px;
    color: #474747;
    font-weight: 500;
    margin-bottom: 10px;
    padding: 3px 10px;
    background: #f9f9f9;
    border-radius: 12px;
    width: max-content;
}

.HeavyEquipment .grid-card .gaInfo p {
    font-size: 16px;
    color: #474747;
    font-weight: 400;
    margin-bottom: 15px;
}

.HeavyEquipment .grid-card .gaInfo p span {
    font-weight: 500;
    padding-right: 10px;
}

.HeavyEquipment .grid-card .gaInfo .gaDesc {
    font-size: 15px;
    color: #707070;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 6.5ex;
}

.HeavyEquipment .grid-card .card-body .timer-wrapper {
    position: absolute;
    top: 160px;
    left: 20px;
}

.HeavyEquipment .grid-card .card-body .timer-wrapper .timerCnt {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.HeavyEquipment .grid-card .card-body .timer-wrapper .timerCnt > span {
    display: block;
    font-size: 12px;
    font-weight: 400;
}

.HeavyEquipment .grid-card .card-body .gaCTA {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeavyEquipment .grid-card .card-body .gaCTA button {
    text-transform: initial;
}

.HeavyEquipment .grid-card .card-body .gaCTA button i {
    margin-right: 5px;
}

.HeavyEquipment .grid-card .card-body .gaCTA .primButton button {
    background: var(--secColor);
    border-color: var(--secColor);
}

.HeavyEquipment .grid-card .card-body .gcTimer {
    position: absolute;
    top: 160px;
    left: 20px;
}

.HeavyEquipment .grid-card .card-body .gcTimer .timerCnt {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.HeavyEquipment .grid-card .card-body .gcTimer .timerCnt > span {
    display: block;
    font-size: 12px;
    font-weight: 400;
}

/* HeavyEquipment Css end*/

/* Automobile Css*/

.Automobile .autoGridInfo {
    display: flex;
    align-items: center;
}

.Automobile .autoGridInfo {
    color: #000;
}

.Automobile .autoGridInfo h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 300;
}

.Automobile .grid-card .timerCnt {
    font-size: 15px;
}

.Automobile .grid-card .card-body {
    padding: 0;
}

.Automobile .autoGridInfo .aucDivider {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000;
    margin: 0 10px;
}

.Automobile .grid-card .card-body > h5 {
    font-size: 20px;
    color: #000;
    font-weight: 500;
    margin: 25px 0 10px;
    line-height: 24px;
    overflow: hidden;
    height: auto;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 10px;
}

.Automobile .grid-card .gcTimer {
    padding: 5px 10px;
    border: 1px dashed gray;
    background: rgb(248, 248, 248);
    margin-bottom: 10px;
    border-radius: 4px;
    color: black;
}

.Automobile .grid-card .grid-img-wrap img {
    height: 225px;
}

.Automobile .grid-card .gaInfo p {
    color: gray;
    margin-bottom: 5px;
}

.Automobile .grid-card .gaCTA {
    text-align: center;
}

.Automobile .grid-card .gaCTA button {
    margin-bottom: 5px;
}

/* Automobile Css end*/

.lineItemBidTable .subLotLine {
    width: 350px;
}

.lineItemBidTable .subLotLine .secButton {
    width: 100%;
    height: 40px;
}

.lineItemBidTable .subLotLine .secButton button {
    height: 40px;
}

.lineItemBidTable .subLotLine .biddingCnt.notLogged .primButton {
    width: 100%;
    margin-bottom: 15px;
}

.lineItemBidTable .subLotLine .slAct,
.lineItemBidTable .subLotLine .actCnt,
.lineItemBidTable .subLotLine form,
.lineItemBidTable .subLotLine .biddingCnt.notLogged,
.lineItemBidTable .subLotLine .biddingCnt.notLogged button {
    width: 100% !important;
}

.lineItemBidTable .subLotLine .biddingCnt.notLogged button {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.FoodIndustry .gallery.card.grid-card .galleryPrice {
    margin-bottom: 0px;
}

.wngSts {
    text-align: center;
    height: 37px;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    border-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    text-transform: uppercase;
}

.wngSts.grnTxt {
    color: rgb(2, 102, 2);
    background: rgb(185 253 199);
}

.wngSts.LsgTxt {
    color: rgb(188, 7, 7);
    background: rgb(255, 229, 229);
}

/*ART AUCTION*/

.ArtAuction .grid-card .grid-img-wrap,
.Collectibles .grid-card .grid-img-wrap {
    cursor: pointer;
}

.cart .material-icons {
    color: #000;
}

.ArtAuction .grid-card .cart button {
    background-color: #fff !important;
}

.cart .material-icons:hover {
    color: #fff;
}

.ArtAuction .grid-card .cart button:hover {
    background-color: #000 !important;
}

.Collectibles .grid-card .grid-img-wrap img {
    height: 370px;
    object-fit: cover;
}

.ArtAuction .grid-card .grid-img-wrap img {
    object-fit: contain;
}

.ArtAuction .grid-card .card-body .mnCardCntnr.notLogged {
    grid-template-columns: 1fr 1fr;
}

.ArtAuction .grid-card .card-body .mnCardCntnr.notLogged .rtCrdCnr {
    justify-content: flex-end !important;
}

.ArtAuction .grid-card .card-body .mnCardCntnr,
.Collectibles .grid-card .card-body .mnCardCntnr {
    padding: 8px;
    background: #ebebeb;
}

.ArtAuction .artCardHover .priceCrd .bPrice span sub,
.ArtAuction .artCardHover .priceCrd .cPrice span sub {
    bottom: 0;
}

.ArtAuction .artCardHover .priceCrd .bPrice span,
.ArtAuction .artCardHover .priceCrd .cPrice span {
    font-size: 26px;
    font-weight: 700;
    color: var(--secColor);
    margin: auto;
}

.ArtAuction .artCardHover .priceCrd .bPrice h4,
.ArtAuction .artCardHover .priceCrd .cPrice h4 {
    font-size: 16px;
    font-weight: 400;
    color: #808080;
    margin: unset !important;
    margin-bottom: 7px;
}

.ArtAuction .artCardHover .priceCrd .bPrice,
.ArtAuction .artCardHover .priceCrd .cPrice {
}

.ArtAuction .artCardHover .priceCrd {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.ArtAuction .LikeTxt {
    color: #808080;
}

.Marine .rtCrdCnr .favoriteCheck {
    margin-left: 65px;
}

.ArtAuction .artCardHover .ProTit {
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 12px;
    line-height: 24px;
    word-break: break-word;
    color: #0a0a0a;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.ArtAuction .artCardHover {
    position: absolute;
    bottom: -100px;
    top: auto;
    width: 100%;
    height: auto;
    background-color: #fff;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    padding: 5px 8px !important;
}

.ArtAuction .grid-card {
    overflow: hidden;
}

.ArtAuction .grid-card:hover .artCardHover {
    bottom: 80px;
    opacity: 1;
}

.ArtAuction .grid-card .cart button {
    border-radius: 50px !important;
    line-height: initial !important;
    padding: 6px !important;
    min-width: 40px;
    border: 1px solid #000 !important;
}

.ArtAuction .grid-card .cart button .material-icons {
    font-size: 21px !important;
}

.ArtAuction .grid-card .primButton button,
.Collectibles .grid-card .primButton button {
    background-color: var(--secColor);
    height: 35px;
    border: none;
}

.ArtAuction .grid-card .primButton button {
    border-radius: 50px;
    line-height: initial;
}

.ArtAuction .grid-card .favoriteCheck,
.Collectibles .grid-card .favoriteCheck {
    background: transparent;
    height: unset;
}

.ArtAuction .grid-card .favoriteCheck label,
.Collectibles .grid-card .favoriteCheck label {
    color: var(--secColor);
}

.ArtAuction .artCardHover .priceCrd .cPrice,
.ArtAuction .artCardHover .priceCrd .bPrice {
    display: flex;
    white-space: nowrap;
}

.ArtAuction .artCardHover .priceCrd .cPrice span,
.ArtAuction .artCardHover .priceCrd .bPrice span {
    font-size: 16px !important;
    white-space: nowrap;
}

.ArtAuction .artCardHover .priceCrd .cPrice h4,
.ArtAuction .artCardHover .priceCrd .bPrice h4 {
    margin-bottom: 3px !important;
    margin-right: 5px !important;
}

.ArtAuction .grid-card .favoriteCheck .material-icons,
.Collectibles .grid-card .favoriteCheck .material-icons {
    font-size: 28px;
}

.ArtAuction .grid-card .card-body,
.Collectibles .grid-card .card-body {
    position: relative;
    z-index: 0;
    padding: 0;
}

.ArtAuction .grid-card .card-body {
    z-index: 99;
    background: #fff;
}

.ArtAuction .grid-card .card-body .ttlInfo,
.Collectibles .grid-card .card-body .ttlInfo {
    background: white;
    border-radius: 10px 10px 0 0;
    padding: 8px;
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;
    transition: ease-in-out 0.3s;
    height: 60px;
}

.ArtAuction .grid-card .card-body .ttlInfo {
    height: auto;
}

.Collectibles .grid-card .card-body .ttlInfo {
    display: flex;
    position: inherit;
    border-radius: 5px 5px 0 0;
    background: #ebebeb;
    justify-content: space-between;
    height: auto;
}

.Collectibles .grid-card .card-body .ttlInfo .galleryPrice p {
    margin: auto;
}

.ArtAuction .grid-card .ttlInfo h5,
.Collectibles .grid-card .ttlInfo h5 {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.Collectibles .grid-card .ttlInfo h5 {
    width: 65%;
}

.ArtAuction .grid-card:hover .card-body .ttlInfo,
.Collectibles .grid-card:hover .card-body .ttlInfo {
    top: 0px;
}

.ArtAuction .grid-card .galleryPrice,
.Collectibles .grid-card .galleryPrice {
    font-size: 15px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 3px;
}

.ArtAuction .grid-card .MuiButton-label,
.Collectibles .grid-card .MuiButton-label {
    text-transform: initial;
}

.badgeStatus.Lost {
    background: #d71313;
}

.badgeStatus.Won {
    background: #316803;
}

.badgeStatus.Winning {
    background: #316803;
}

.badgeStatus.Outbid {
    background: #d71313;
}
.dashboard-tabs table .badgeStatus {
    font-weight: 700;
}
.dashboard-tabs table .badgeStatus.Won,
.dashboard-tabs table .badgeStatus.Winning {
    color: #316803;
    background: transparent;
}
.wizardTheme .wizardPricing span.unsold,
.wizardTheme .wizardPricing div.sold {
    background-color: #e85149;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    padding: 8px 25px;
}
.wizardTheme .wizardPricing div.sold {
    background-color: #3a8627;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 8px 15px;
}
.wizardTheme .wizardPricing div.sold.activeSlot {
    background-color: #ffffff;
    color: #000;
}
.wizardTheme .wizardPricing div.sold.activeSlot span .prcTxtVal {
    font-size: 24px;
}
.wizardTheme .wizardPricing div.sold.activeSlot span {
    color: var(--primColor) !important;
}
.wizardTheme .wizardPricing div.sold span {
    color: #fff !important;
    font-size: 12px;
}
.dashboard-tabs table .badgeStatus.Outbid,
.dashboard-tabs table .badgeStatus.lost {
    color: #d71313;
    background: transparent;
}

.dashboard-tabs table .badgeStatus {
    padding: 6px 10px;
    width: auto;
}

.badgeStatus {
    padding: 8px 12px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
}

.ArtAuction .grid-card {
    height: auto;
}

/* .Collectibles .grid-card {
    height: 510px;
} */

.Collectibles .grid-last-paragraph {
    background: #ebebeb;
    padding-bottom: 10px;
}

.Auctioneer .grid-card .gcTimer {
    margin: 5px 0;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 210px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 30px);
    max-width: calc(100% - 30px);
    width: max-content;
    padding: 4px 15px;
    border-radius: 15px;
}

.Auctioneer .grid-card .gcTimer.noBackDrop {
    background: transparent;
}

.Auctioneer .grid-card .gcTimer .timerCnt {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Auctioneer .grid-card .gcTimer .timerCnt .material-icons-outlined {
    margin-right: 8px;
}

.gpBidCart {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 10px;
}

.liquidationTwo.primeBids .lctnFavWrapper .card-favourite {
    order: 2;
}

.liquidationTwo.primeBids .lctnFavWrapper .card-location {
    order: 1;
}

.liquidationTwo.primeBids .lctnFavWrapper .lctnTxtWrpr {
    border-radius: 166.653px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
    height: 30px;
    width: max-content !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.6px;
    top: 20px;
    left: 20px;
    padding: 8px 12px;
}

.liquidationTwo.primeBids .notesFavWrapper > .notes:only-child,
.liquidationTwo.primeBids .notesFavWrapper > .favoriteCheck:only-child {
    margin-left: auto !important;
}

.liquidationTwo.primeBids .lctnFavWrapper .lctnTxtWrpr.notes {
    cursor: pointer;
}

.liquidationTwo.primeBids .lctnFavWrapper .favoriteCheck {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: transparent;
}

.liquidationTwo.primeBids .lctnFavWrapper .favoriteCheck .material-icons {
    text-shadow: 0 0 13px #fc4040;
    filter: drop-shadow(0px 0px 6px #ffcfcf);
    -webkit-filter: drop-shadow(0px 0px 6px #ffcfcf);
}

.liquidationTwo.primeBids .lctnFavWrapper .favoriteCheck label {
    color: #ff4646;
}

.liquidationTwo.primeBids .lctnFavWrapper .favoriteCheck input:checked + label {
    color: #ff4646;
}

.liquidationTwo.primeBids .lctnFavWrapper .card-location .lctnTxtWrpr {
    justify-content: flex-start !important;
}

.liquidationTwo.primeBids .ratingLiqThree {
    display: flex !important;
}

.liquidationTwo.primeBids .ratingLiqThree.showReview {
    display: block !important;
}

.liquidationTwo.primeBids .swiper-wrapper .assuranceTxt {
    min-height: 97px !important;
}

.liquidationTwo.primeBids .auction.gallery .assuranceTxt {
    min-height: auto;
}

/* Emurse */
.liquidationTwo.emursMck .lctnFavWrapper .card-favourite {
    order: 2;
}

.liquidationTwo.emursMck .lctnFavWrapper .card-location {
    order: 1;
}

.liquidationTwo.emursMck .lctnFavWrapper .lctnTxtWrpr {
    border-radius: 166.653px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
    height: 30px;
    width: max-content !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.6px;
    top: 20px;
    left: 20px;
    padding: 8px 12px;
}

.liquidationTwo.emursMck .notesFavWrapper > .notes:only-child,
.liquidationTwo.emursMck .notesFavWrapper > .favoriteCheck:only-child {
    margin-left: auto !important;
}

.liquidationTwo.emursMck .lctnFavWrapper .lctnTxtWrpr.notes {
    cursor: pointer;
}

.liquidationTwo.emursMck .grid-card .gcTimer {
    color: var(--secColor);
}

.liquidationTwo.emursMck .grid-card .gcTimer .actualClass {
    display: flex;
}

.liquidationTwo.emursMck .lctnFavWrapper .favoriteCheck {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: transparent;
}

.liquidationTwo.emursMck .lctnFavWrapper .favoriteCheck .material-icons {
    text-shadow: 0 0 13px #fc4040;
    filter: drop-shadow(0px 0px 6px #ffcfcf);
    -webkit-filter: drop-shadow(0px 0px 6px #ffcfcf);
}

.liquidationTwo.emursMck .lctnFavWrapper .favoriteCheck label {
    color: #ff4646;
}

.liquidationTwo.emursMck .lctnFavWrapper .favoriteCheck input:checked + label {
    color: #ff4646;
}

.liquidationTwo.emursMck .lctnFavWrapper .card-location .lctnTxtWrpr {
    justify-content: flex-start !important;
}

.liquidationTwo.emursMck .ratingLiqThree {
    display: flex !important;
}

.liquidationTwo.emursMck .ratingLiqThree.showReview {
    display: block !important;
}

.liquidationTwo.emursMck .swiper-wrapper .assuranceTxt {
    min-height: 97px !important;
}

.liquidationTwo.emursMck .auction.gallery .assuranceTxt {
    min-height: auto;
}

/* end */
.gpBidCart.spByNw {
    height: 40px;
    margin-top: 10px;
}

.gpBidCart.spByNw .primButton {
    width: 100%;
}

.gpBidCart .customInput {
    margin-bottom: 0;
}
.wizardTheme .gpBidCart form button {
    border-radius: 8px !important;
}
.gpBidCart form button {
    border-radius: 4px 4px 4px 4px !important;
}
.wizardTheme .card-body .gpBidCart form .customInput .MuiInputBase-root {
    border-radius: 8px 0 0 8px;
}
.wizardTheme .card-body .gpBidCart form .customInput .MuiInputBase-root fieldset {
    border-color: var(--primColor);
    border-right: 0;
}
.wizardTheme .card-body .gpBidCart form .bid-wrapper .primButton {
    width: 60%;
}
.wizardTheme .card-body .gpBidCart form .bid-wrapper .primButton button {
    background-color: var(--secColor);
    border-left: 0;
    font-weight: 700;
}
.gpBidCart .cartBtn {
    width: 50px;
    border: 1px solid currentColor;
    color: var(--secColor);
    min-width: initial;
}

.liquidationTwo.wizardTheme .gpAddnInfo {
    justify-content: space-around;
    margin: 10px 0;
    gap: 10px;
}

.gpAddnInfo {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gpAddnInfo span {
    font-weight: 400;
    margin-left: 5px;
}

.grid-card .card-body .biddingCnt {
    display: grid !important;
    grid-template-columns: 55% 45%;
}

.bidNaa .grid-card .card-body .biddingCnt {
    grid-template-columns: 100%;
}
.wizardTheme .grid-card .card-body .biddingCnt {
    display: flex !important;
}
.MultiAuction .grid-card .card-body .biddingCnt {
    display: grid !important;
    grid-template-columns: 1fr;
    row-gap: 10px;
}

.MultiAuction .grid-card .card-body .biddingCnt .customInput,
.MultiAuction .msSublots .lotResults.Grid .biddingCnt .customInput {
    margin-right: 0px;
}

.MultiSeller_two .gcTimer {
    min-height: 67.8px;
    margin-bottom: 15px;
}

.MultiSeller_two .grid-card.buynow .card-body .primButton button {
    height: 40px;
}

.Auctioneer .gpBidCart form button {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
}

.Auctioneer .gpBidCart .cartBtn {
    height: 40px;
}

.MultiSeller_two .grid-card.buynow .timer-wrapper {
    background-color: transparent;
}

.Auctioneer .grid-card .galleryPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.viewsCountValue {
    position: absolute;
    bottom: 8px;
    margin: 0;
    right: 15px;
    background: black;
    color: white;
    padding: 5px 20px;
    border-radius: 100px;
}

.Automobile .grid-card .card-body > h5.hostedBy {
    font-size: 15px;
    color: #525252;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 5px;
}

.Auctioneer .liquidationBuyCard .primButton button {
    height: 40px;
    border-radius: 4px;
}

.lctnTxtWrpr {
    color: var(--primColor);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
}

.lctnTxtWrpr .material-icons {
    font-size: 18px;
}

.lctnFavWrapper {
    margin-bottom: 8px;
    flex-wrap: nowrap;
}

.liquidationTwo.wizardTheme .grid-card .img-notes-wrap {
    height: 250px;
}

.liquidationTwo.wizardTheme .card .name-txt {
    font-weight: 600;
    font-size: 17px;
    text-align: left;
    color: #000;
    margin: 5px 0;
    height: 52px;
    line-height: 26px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
}

.liquidationTwo .card .name-txt {
    font-weight: 600;
    font-size: 17px;
    text-align: left;
    color: #000;
    margin: 5px 0;
    height: 46px;
    line-height: 23px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
}

.liquidationTwo.hyperMicro .card .name-txt {
    font-size: 20px;
    text-align: center;
}

.liquidationTwo .card.grid-card {
    background: white;
    border-radius: 0px;
    box-shadow: 0px 3px 6px #d3d3d3;
    /* min-height: 562px; */
    padding: 5px;
}
.wizardTheme .wizardGalleryPrice {
    flex-direction: column-reverse;
}

.liquidationTwo.wizardTheme .card.grid-card {
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 15px;
    box-shadow: none;
}

.liquidationTwo.wizardTheme .grid-card .card-location {
    display: none;
}

.liquidationTwo.wizardTheme .grid-card .grid-img-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 12px;
}

.liquidationTwo.wizardTheme .lctnFavWrapper {
    margin-bottom: 0px !important;
    position: absolute;
    top: 24px;
    right: 16px;
    background-color: #ffffffc9;
    z-index: 3;
    padding: 5px 6px;
    border-radius: 8px;
    width: 95px;
}

.liquidationTwo.wizardTheme .grid-card .name-txt {
    background-color: #000;
    opacity: 0.84;
    border-radius: 8px;
    padding: 6px 10px;
    color: #fff;
    font-weight: 600;
    height: fit-content;
    position: absolute;
    top: 255px;
    left: 0;
    margin: 0 8px;
}

.wizardTheme .wizardGalleryPrice .prcLabel {
    color: #aeaeae;
    font-size: 12px;
    font-weight: 700;
}
.wizardTheme .wizardGalleryPrice .prcTxtVal {
    color: #000 !important;
    font-size: 18px;
    font-weight: 700 !important;
    text-decoration: line-through;
}
.wizardTheme .wizardGalleryCondition .prcTxtVal {
    font-size: 14px;
}
.wizardPricing span:first-child {
    font-weight: 700;
    color: var(--primColor);
}
.wizardTheme .grid-card .card-body {
    margin-top: 35px;
    padding: 12px 0 0;
}
.wizardTheme .grid-card .authWizardCardBody {
    margin-top: 5px;
}
.wizardCardTimer {
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: var(--primColor);
    border-radius: 8px;
    padding: 0px 12px;
}
.wizardCardTimer .hoTimer .customFlexBox {
    flex-direction: column-reverse;
    gap: 2px;
}
.wizardCardTimer .hoTimer .customFlexBox .actualClass {
    font-size: 14px;
    display: flex;
    align-items: center;
}
.wizardCardTimer .hoTimer .customFlexBox .timeClass {
    font-size: 16px;
    font-weight: 700;
}
/* .slider-container .wizardCardTimer .hoTimer .customFlexBox .timeClass {
    font-size: 12px;
    font-weight: 700;
} */
.wizardCardTimer .hoTimer {
    height: 100% !important;
}
.wizardPricing span:last-child {
    font-weight: 700 !important;
    font-size: 22px;
}
.liquidationTwo .pdtStsInfo {
    background: whitesmoke;
    margin: 0 -8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    padding: 5px;
}

.liquidationTwo .bidsCount {
    cursor: pointer;
}

.liquidationTwo.primeBids .bidsCount {
    cursor: unset;
}

.liquidationTwo .pdtStsInfo ul {
    list-style: none;
    padding-left: 0;
    font-size: 12px;
    margin: 0;
}
.liquidationTwo.emursMck .ratingLiqThree {
    display: none !important;
}
.liquidationTwo .pdtStsInfo ul li {
    display: flex;
    align-items: center;
}

.liquidationTwo .pdtStsInfo ul li:not(:last-child) {
    margin-bottom: 5px;
}

.liquidationTwo .pdtStsInfo ul li label,
.liquidationTwo .pdtStsInfo ul li p {
    margin: 0;
}

.liquidationTwo .pdtStsInfo ul li label {
    margin-right: 5px;
}

.liquidationTwo .pdtStsInfo ul li p {
    font-weight: 600;
    white-space: pre;
    width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.descPopup {
    min-width: 200px;
    width: 100%;
    text-align: center;
    padding: 5px;
    display: block;
    opacity: 1;
    z-index: 1000;
}
.wizardTheme .grid-card .favoriteCheck {
    margin-left: 0 !important;
}
/* .descPopup:hover
{
    opacity: 0.5;
} */
.liquidationTwo .card.grid-card .galleryPrice {
    font-size: 13px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px 0;
}

.LiquidationThreeBuyCard .hostedBy,
.ratingLiqThree,
.conditionLiq {
    display: none;
}

.LiquidationThree .onlyHomeLiqCard {
    display: none;
}

.LiquidationThree .lctnTxtWrpr {
    background-color: #fff;
    color: #000;
    position: absolute;
    top: 5%;
    padding: 6px 10px;
    left: 10%;
    border-radius: 24px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.LiquidationThree .noTimeLeft .actualTimer {
    display: none;
}

.LiquidationThree .favoriteCheck {
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 42%;
    right: 5%;
    width: 45px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.LiquidationThreeBuyCard .grid-card {
    padding: 20px;
    border-radius: 25px;
    border: 1px solid transparent;
    background-color: #fafafa;
}

.LiquidationThreeBuyCard .grid-card:hover {
    border: 1px solid #b8b8b8;
}

.LiquidationThree .favoriteCheck span {
    color: #000;
    font-size: 26px;
}

.LiquidationThree .homeGridProducts .onlyHomeLiqCard {
    display: block;
}

/* .Liquidation .gcTimer .timerCnt .actualTimer {
    color: red;
    font-weight: 700;
} */

.LiquidationThree .gcTimer .timerCnt div {
    text-align: right;
    font-size: 13px;
}

.LiquidationThreeBuyCard .ratingLiqThree .MuiSvgIcon-root {
    stroke: #faaf00;
    fill: #faaf00;
    margin-right: 3px;
}

.LiquidationThreeBuyCard .ratingLiqThree .MuiRating-iconEmpty .MuiSvgIcon-root {
    fill: transparent;
}

.LiquidationThreeBuyCard .ratingLiqThree {
    display: block;
    margin-top: 2px;
}

.LiquidationThreeBuyCard .conditionLiq {
    display: block;
    min-height: 27px;
    margin: 8px 0px;
}

.LiquidationThreeBuyCard .conditionLiq .cursorDecoy {
    padding: 3px 20px;
    width: fit-content;
    border-radius: 25px;
    font-size: 14px;
    background-color: transparent;
}

.LiquidationThreeBuyCard .galleryPrice {
    display: flex;
    flex-direction: column-reverse;
}

.LiquidationThreeBuyCard .galleryPrice span {
    color: #828282;
    font-size: 14px;
    font-weight: 400;
}

.LiquidationThreeBuyCard .newCond {
    font-size: 14px;
    background-color: #ffeaef;
    padding: 5px 15px;
    width: fit-content;
    border-radius: 23px;
    margin: 5px 0px 12px;
}

.LiquidationThreeBuyCard .newCond .prcTxtVal {
    font-size: 14px !important;
    color: var(--primColor) !important;
    font-weight: 500 !important;
}

.LiquidationThreeBuyCard .conditionLiq .cursorDecoy.text-danger {
    background-color: transparent;
}

.LiquidationThreeBuyCard .conditionLiq .cursorDecoy.text-success {
    background-color: #edfff9;
}

/* .LiquidationThree .grid-img-wrap .curve {
    position: absolute;
    background-image: url(../../assets/images/curveImg.png);
    bottom: -2px;
    height: 43px;
    background-repeat: no-repeat;
    width: 100%;
    z-index: 2;
    background-size: cover;
} */

.liquidationTwo .card.grid-card .galleryPrice .prcTxtVal {
    color: var(--primColor);
    font-weight: 600;
    padding-left: 5px;
}

.liquidationTwo.primeBids .card.grid-card .redFlashTmr {
    color: #f70b0b;
}

.liquidationTwo.primeBids .modal-dialog .modal-title {
    text-align: left !important;
}

.liquidationTwo .card.grid-card .gpBidCart {
    display: flex;
}

.liquidationTwo .card.grid-card .gpBidCart form {
    width: 100%;
}

.liquidationTwo .grid-card .card-body .biddingCnt {
    gap: 6px;
    grid-template-columns: 1fr 1fr;
}

.liquidationTwo .liquidationBuyCard .primButton button {
    border-radius: 4px;
}

.liquidationTwo.hyperMicro .liquidationBuyCard .primButton button {
    background-color: var(--secColor);
    border-color: var(--secColor);
    color: var(--primColor);
}

/* .liquidationTwo.hyperMicro .liquidationBuyCard .primButton button .MuiButton-label {
    font-size: 18px;
    font-weight: 600;
} */

.liquidationTwo .card .endDteTxt {
    color: var(--primColor);
    margin-top: 10px;
    font-weight: 600;
}

.liquidationTwo.primeBids .card .endDteTxt {
    display: none;
}

.liquidationTwo .multisellerListCard .grid-img-wrap .lctnTxtWrpr {
    bottom: unset;
    right: unset;
    top: 0px;
}

.grid-img-wrap {
    position: relative;
}

.grid-img-wrap .soldWrapper {
    background: #00000096;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 59px;
    color: #ffffffa3;
    font-weight: bold;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-img-wrap .soldWrapper p {
    margin: 0;
    transform: rotate(324deg);
}

.CarAuction .card.grid-card .grid-img-wrap {
    height: 250px;
    width: 100%;
    overflow: hidden;
}

.CarAuction .card.grid-card .grid-img-wrap img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: ease-in-out 0.4s;
}

.CarAuction .card.grid-card:hover .grid-img-wrap img {
    scale: 1.1;
}

.CarAuction .card.grid-card {
    box-shadow: unset;
    border-radius: 8px;
    padding: 0px;
}

.CarAuction .card.grid-card .name-txt {
    text-align: center;
}

.CarAuction .grid-card .gcTimer {
    text-align: left;
}

.CarAuction .grid-card .crncyValue,
.CarAuction .grid-card .timrVlue {
    display: flex;
    align-items: center;
}

.CarAuction .grid-card .crncyValue .material-icons-outlined,
.CarAuction .grid-card .timrVlue .material-icons-outlined {
    color: var(--primColor);
    font-size: 20px;
    margin-right: 10px;
}

.CarAuction .cntBidOwn {
    display: none;
}

.galleryPrice.rtlrPrice {
    color: var(--primColor);
    min-height: 17px;
}

.CarAuction .liquidationBuyCard .primButton button {
    border-radius: 2px;
    height: 40px;
}

.gpBidCart.carAucBid {
    display: flex;
}

.gpBidCart.carAucBid form {
    width: 100%;
}

.gpBidCart.carAucBid .biddingCnt {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.cartBtn.crActnCart {
    position: absolute;
    z-index: 9;
    color: white;
    background: rgb(0, 0, 0, 20%);
    right: 0;
    top: 45px;
    width: 40px;
    min-width: unset;
    border-radius: 0px;
    height: 45px;
}

.Liquidation .favGrid.buynow .galleryPrice.actnPriceVlue .crncyNtAvlblVlue {
    display: none;
}

.liquidationTwo .noBidDiv {
    height: 50px;
}

.liquidationTwo .grid-card .favoriteCheck {
    height: 20px;
}

.liquidationTwo .grid-card .grid-img-wrap {
    border: 5px solid transparent;
}

.liquidationTwo .winningcard .grid-img-wrap,
.liquidationTwo .auctionwinningcard .grid-img-wrap {
    border: 5px solid rgb(2, 102, 2);
}

.liquidationTwo .losingcard .grid-img-wrap {
    border: 5px solid rgb(188, 7, 7);
}

.liquidationTwo .losingcard .wngSts,
.liquidationTwo .winningcard .wngSts,
.liquidationTwo .auctionwinningcard .wngSts {
    bottom: auto;
    top: 0;
}

.wizardTheme .losingcard .wngSts,
.wizardTheme .winningcard .wngSts,
.wizardTheme .auctionwinningcard .wngSts {
    width: fit-content;
    border-radius: 12px;
    height: unset;
    padding: 5px 20px;
    font-size: 14px;
    top: 8px !important;
    left: 5px;
}

.liquidationTwo .winningcard .wngSts.grnTxt,
.liquidationTwo .auctionwinningcard .wngSts.grnTxt {
    color: #fff;
    background: rgb(2, 102, 2);
}

.Liquidation .retailMaintainHeight {
    min-height: 22px;
    height: 100%;
}

.liquidationTwo .losingcard .wngSts.LsgTxt {
    color: #fff;
    background: rgb(188, 7, 7);
}

.liquidationTwo .mslMisc .galleryPrice.rtlrPrice {
    min-height: initial;
}

.liquidationTwo .mslMisc .biddingCnt .customInput {
    margin-bottom: 0 !important;
}

.liquidationTwo .grid-img-wrap .lctnTxtWrpr {
    position: absolute;
    bottom: 5px;
    background: #fff;
    right: 5px;
    padding: 2px 4px;
    border-radius: 5px;
}

.text-Yellow {
    color: #ffcc00;
}

.Collectibles .grid-card .card-body {
    position: static !important;
}

.Collectibles .grid-card .favoriteCheck {
    position: absolute;

    top: 0;

    right: 0;
}

.Collectibles .grid-card .gridItemAct {
    display: grid;

    grid-template-columns: 11fr 1fr;

    gap: 10px;
}

.Collectibles .grid-card .gridItemAct .cartBtn {
    min-width: initial;

    width: 36px;

    height: 36px;

    border: 1px solid var(--secColor);

    color: var(--secColor);

    background: #fff;
}

.Collectibles .grid-card .gridItemAct .cartBtn.active {
    background: var(--secColor);

    color: #fff;
}

.Collectibles .grid-card .card-body .ttlInfo,
.Collectibles .grid-card .card-body .mnCardCntnr,
.Collectibles .grid-last-paragraph {
    background: #f3f3f3 !important;
}

.Collectibles .grid-card .timerMob {
    margin-top: 10px;
}

.LotsAuction .lotAuctionCart .cartBtn {
    position: absolute;
    top: 0px;
    right: 0;
}

.LotsAuction .winBtnWrpr .primButton button,
.LotsAuction .outbidBtnWrpr .primButton button {
    border: none;
    letter-spacing: 0.12em;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    height: 22px;
    outline: none !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
}

.LotsAuction .winBtnWrpr .primButton button {
    background: #5ec26a !important;
    background: linear-gradient(
        90deg,
        #fff 0%,
        #5ec26a 25%,
        #5ec26a 50%,
        #5ec26a 75%,
        #fff 100%
    ) !important;
}

.LotsAuction .outbidBtnWrpr .primButton button {
    background: #f20505 !important;
    background: linear-gradient(
        90deg,
        #fff 0%,
        #f20505 25%,
        #f20505 50%,
        #f20505 75%,
        #fff 100%
    ) !important;
}

.LotsAuction .grid-card .gridItemAct.winBtnWrpr,
.LotsAuction .grid-card .gridItemAct.outbidBtnWrpr {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
}

.LotsAuction .loaCategory {
    gap: 5px;
    max-height: 50px;
    min-height: 50px;
    line-height: normal;
    margin-bottom: 5px;
}

.LotsAuction .grid-card .gridItemAct.winBtnWrpr .primButton button,
.LotsAuction .grid-card .gridItemAct.outbidBtnWrpr .primButton button {
    height: 22px;
    color: #fff !important;
}

.LotsAuction .glot-id,
.LotsAuction .glot-id h6 {
    color: #000000;
    font-size: 13px;
    letter-spacing: 0.01px;
    font-weight: 600;
    width: 100%;
    display: block;
}

.LotsAuction .glot-id h6 {
    padding: 4px 0;
    margin: 0;
}

@media (max-width: 1260px) {
    .liquidationTwo.primeBids .swiper-wrapper .assuranceTxt {
        min-height: 73px !important;
    }
}

/* Responsive Design */

@media (max-width: 1100px) {
    .HeavyEquipment .multisellerListCard .grid-img-wrap {
        min-width: 150px;
        max-width: 150px;
    }
}

@media (min-width: 768px) {
    .text-center-md {
        text-align: center;
    }
}

@media (max-width: 991px) {
    .li-timer-wrap {
        display: flex;
        align-items: center;
    }

    .auctionsSearch .li-timer-wrap {
        justify-content: center;
    }

    .MultiSeller .auctionsSearch .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }

    .MultiSeller .timerWrapper .timeLabel::before {
        left: 0%;
    }

    .MultiSeller .timerWrapper .timeLabel::after {
        right: 0%;
    }

    .MultiSeller .ftrDtlCntnr {
        justify-content: center;
    }

    .MultiSeller .auctionsSearch .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }

    .li-timer-wrap .lv-timer {
        margin: 0 10px 0 0;
        height: 24px;
    }

    .li-timer-wrap .li-timer {
        font-weight: 500;
        font-size: 14px;
        color: #2c2c37;
        margin-bottom: 0;
    }

    .HeavyEquipment .multisellerListCard .mslDesc {
        width: 75% !important;
        border-right: 0;
    }

    .liquidationTwo .multisellerListCard .mslDesc {
        width: 100% !important;
        border-right: none;
    }
}

@media (max-width: 800px) {
    .liquidationTwo .pdtStsInfo ul li p {
        width: -webkit-fill-available;
        min-width: 80px;
    }

    .HeavyEquipment .multisellerListCard .mslDesc {
        width: 75%;
        border-right: 0;
    }

    .HeavyEquipment .multisellerListCard .mslMisc {
        min-width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }

    .HeavyEquipment .multisellerListCard .mslMisc .timerCnt {
        margin-left: 170px;
    }

    .ArtAuction .artCardHover .priceCrd .cPrice,
    .ArtAuction .artCardHover .priceCrd .bPrice {
        white-space: nowrap;
    }

    .artAuctionTimer {
        margin-bottom: 2px;
    }

    .ArtAuction .artCardHover .priceCrd {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }
}

@media (max-width: 767px) {
    .HeavyEquipment .grid-card .card-body .timer-wrapper {
        background: transparent;
        padding: 0;
    }
    .liquidationTwo.wizardTheme .lctnFavWrapper {
        top: 13px;
        right: 29px;
    }
    .LiquidationThree .lctnTxtWrpr {
        left: 2%;
        top: 2%;
    }
    .wizardTheme .grid-card .authWizardCardBody {
        margin-top: 25px;
    }
    .wizardCardTimer .hoTimer .customFlexBox .timeClass {
        font-size: 12px;
        font-weight: 700;
    }
    .LiquidationThree .favoriteCheck {
        top: 2%;
        right: 3%;
    }

    .mt-5,
    .my-5 {
        margin-top: 1rem !important;
    }

    .list-item-wrapper .item-title {
        text-align: center;
    }

    .ArtAuction .grid-card .cart {
        border-radius: 50px !important;
        line-height: initial !important;
        padding: 6px !important;
        min-width: 40px;
        margin-right: 20px;
        border: 1px solid #000 !important;
    }

    .list-item-wrapper .media-left {
        width: 100px;
        margin-right: 10px;
    }

    .list-item-wrapper .grid-img-wrap img.item-img {
        height: 100px;
        width: 100px;
    }

    .list-item-wrapper .grid-img-wrap .react-loading-skeleton {
        height: 100px !important;
    }

    .list-item-wrapper .media-body {
        padding: 0 0 0 10px;
        max-width: calc(100% - 135px);
    }

    .fw-wrap {
        flex-wrap: wrap;
    }

    .fw-wrap > div {
        width: 100%;
    }

    .MultiSeller .auctionsSearch .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }

    .MultiSeller .timer-wrapper {
        background: #ffffff;
    }

    .MultiSeller .multiSellerTimerView {
        margin-bottom: 0;
    }

    .HeavyEquipment .multisellerListCard .grid-img-wrap {
        min-width: 190px;
        max-width: 190px;
    }

    .HeavyEquipment .multisellerListCard .mslMisc .timerCnt {
        margin-left: 0;
    }

    .Collectibles .grid-card .mnCardCntnr .rtCrdCnr {
        width: 100%;
    }

    .Collectibles .grid-card .mnCardCntnr .rtCrdCnr .primButton {
        margin-left: 0 !important;
    }
}

@media (max-width: 760px) {
    .HeavyEquipment .multisellerListCard .grid-img-wrap {
        width: 100%;
        min-width: unset;
        max-width: unset;
        margin-bottom: 10px;
    }

    .HeavyEquipment .grid-img-wrap {
        width: 100%;
        height: 227px;
        overflow: hidden;
        border-radius: 5px;
    }
}

@media (max-width: 665px) {
    .HeavyEquipment .multisellerListCard .mslDesc {
        width: 70%;
    }

    .Auctioneer .grid-card .gcTimer {
        margin: 5px 0;
        background: rgba(0, 0, 0, 0.4);
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
        width: unset;
        max-width: unset;
        width: unset;
        padding: 4px 15px;
        border-radius: 15px;
        margin-bottom: 10px !important;
    }

    .Auctioneer .grid-card .gcTimer .closedText {
        color: white;
    }

    /* .liquidationTwo .card.grid-card .name-txt, */
    /* .liquidationTwo .card.grid-card .pdtStsInfo, */
    /* .liquidationTwo .card.grid-card .galleryPrice.rtlrPrice,
    .liquidationTwo .card.grid-card .gpBidCart,
    .liquidationTwo .card.grid-card .noBidDiv,
    .liquidationTwo .card.grid-card .primButton {
        display: none;
    } */

    .liquidationTwo .card.grid-card .endDteTxt {
        font-size: 12px;
    }

    .liquidationTwo .card.grid-card .grid-img-wrap img {
        height: 200px;
        object-fit: contain;
    }

    .liquidationTwo .grid-card .grid-notes {
        height: 200px;
    }

    .liquidationTwo .grid-card .fiveStar {
        height: 200px;
    }

    .liquidationTwo .card.grid-card .galleryPrice.actnPriceVlue,
    .liquidationTwo .card.grid-card .galleryPrice {
        font-size: 15px;
        justify-content: center;
        margin: 7px 0px;
    }

    /* .liquidationTwo .card.grid-card .galleryPrice .prcTxtVal {
        white-space: nowrap;
    } */
    .liquidationTwo .card.grid-card .galleryPrice > span:first-child {
        width: 100%;
        text-align: left;
        padding-bottom: 5px;
    }

    .liquidationTwo .card.grid-card .card-body {
        padding: 5px 0;
    }
}

@media (max-width: 600px) {
    .ArtAuction .grid-card .card-body .mnCardCntnr > .d-flex {
        justify-content: flex-start !important;
    }

    .ArtAuction .grid-card .card-body .mnCardCntnr > .d-flex.rtCrdCnr {
        justify-content: flex-end !important;
    }

    .ArtAuction .grid-card .favoriteCheck {
        margin-right: 12px;
    }

    .liquidationTwo .card.grid-card .gpBidCart.spByNw.hide {
        display: block;
    }

    .HeavyEquipment .grid-card .list-title {
        font-size: 18px;
        text-align: left;
        height: auto;
        margin-bottom: 5px;
    }

    .HeavyEquipment .grid-card .gaInfo p {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .list-title {
        font-size: 18px;
    }

    .MultiSeller .grid-card .li-timer,
    .MultiSeller .grid-card .gcTimer {
        height: initial;
    }

    .HeavyEquipment .gallery.card.grid-card .name-txt,
    .MultiSeller .gallery.card.grid-card .name-txt {
        height: initial;
        font-size: 16px;
    }

    .MultiSeller .auctionsSearch .timer-wrapper {
        background: #fff;
        margin-bottom: 0;
    }

    .MultiSeller .timerWrapper .timeLabel::after {
        right: -5%;
    }

    .MultiSeller .timerWrapper .timeLabel::before {
        left: -5%;
    }

    .multiSellerTimerView {
        margin: 10px 0 5px;
    }

    .lineItemBidTable .subLotLine {
        width: 100%;
    }

    .sublots-table .sl-pbids .row .flex-wrap.actCnt {
        padding-right: 0 !important;
    }

    .liquidationTwo .pdtStsInfo {
        margin: 0 -5px;
    }
}
@media (min-width: 550px) {
    .BidAuction .lotResults .multisellerListCard .mslDesc .quckBidcontain form {
        min-width: 170px;
    }
}
@media (max-width: 525px) {
    .HeavyEquipment .multisellerListCard .grid-img-wrap {
        min-width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 500px) {
    .ArtAuction .grid-card .card-body .mnCardCntnr {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        padding: 18px;
    }

    .liquidationTwo.primeBids .searchContainer .lotResults.Grid .grid-card .card-body {
        display: block;
    }

    .liquidationTwo.primeBids .searchContainer .lotResults.Grid .grid-card .card-body {
        display: block;
    }

    .ArtAuction .grid-card .card-body .rtCrdCnr .primButton {
        margin-left: 0 !important;
    }

    .location-class {
        font-size: 10px !important;
    }

    .liquidationTwo .pdtStsInfo ul li p {
        width: 100%;
    }

    .liquidationTwo .searchContainer .card.grid-card .gpBidCart {
        margin-top: auto;
    }

    .liquidationTwo .grid-card .fiveStarText {
        font-size: 14px;
    }

    .liquidationTwo .grid-card .fiveStar {
        height: 170px;
    }

    .list-title {
        font-size: 16px;
    }

    .HeavyEquipment .gallery.card.grid-card .name-txt,
    .MultiSeller .gallery.card.grid-card .name-txt {
        font-size: 14px;
        max-height: 5ex;
        height: auto;
    }

    .gcTimer,
    .HeavyEquipment .gallery.card.grid-card .galleryPrice,
    .MultiSeller .gallery.card.grid-card .galleryPrice {
        font-size: 13px;
    }

    .MultiSeller .auctionsSearch .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .MultiSeller .auctionsSearch .grid-card .grid-img-wrap img {
        height: 220px;
    }

    .card .lotCount {
        padding: 2px 10px;
        font-size: 12px;
    }

    /* .MultiSeller .biddingCnt.d-flex.justify-content-between.align-items-start > div {
        width: 50%;
    } */
    .MultiSeller .grid-card .biddingCnt > div:first-child {
        width: 100%;
        max-width: initial;
    }
}

@media (max-width: 460px) {
    .liquidationTwo .Grid.liquidationBuyCard .primButton button .MuiButton-label,
    .liquidationTwo .liquidationBuyCard .primButton button {
        font-size: 11px;
        padding: 6px 5px;
    }

    .liquidationTwo .card.grid-card .galleryPrice > span:last-child,
    .liquidationTwo .card.grid-card .galleryPrice > span:first-child {
        font-size: 12px;
    }

    .liquidationTwo .liquidationBuyCard .primButton button {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 425px) {
    .ArtAuction .grid-card .favoriteCheck,
    .Collectibles .grid-card .favoriteCheck {
        width: 10px;
    }
}

.LotsAuction .bids-buttons-wrap {
    display: flex;
    justify-content: space-between;
}

.LotsAuction .bids-buttons-wrap > * {
    width: 48%;
}

.LotsAuction .bids-buttons-wrap > .MuiButton-root {
    border-radius: 8px;
    background: #f1f6ff;
    color: var(--primColor);
    border: 1px solid #f1f6ff;
    text-transform: capitalize;
    font-weight: 600;
    height: 50px;
}

.LotsAuction .container.auctionsSearch {
    margin-bottom: 50px;
}

.LotsAuction .favoriteCheck label {
    color: #ff3838 !important;
    margin: 0;
    width: 24px;
    height: 24px;
}

.LotsAuction .grid-card .gridItemAct.grid-bid-btns {
    column-gap: 10px;
}

.LotsAuction .grid-card .gridItemAct.grid-bid-btns .primButton {
    width: 144px;
}

.LotsAuction .grid-card .gridItemAct.grid-bid-btns .cartBtn {
    width: 144px;
    height: 50px;
    background: #f1f6ff;
    border: 2px solid currentColor;
    color: var(--primColor);
    min-width: 50px;
    margin: 0;
    border-radius: 8px;
    opacity: 1;
    border-color: #f1f6ff;
    font-weight: 600;
    text-transform: capitalize;
}

.LotsAuction .conditionLiq.loa-condition {
    display: block !important;
}

.LotsAuction .conditionLiq.loa-condition p {
    height: 20px;
    background: #e9e9f8;
    border-radius: 40px;
    display: inline-block;
    padding: 3px 8px;
    font-size: 12px;
    line-height: 14px;
    color: #284480;
    font-weight: 500;
}

.LotsAuction .grid-card .grid-img-wrap .grid-loc img,
.LotsAuction .liquidationBuyCard .card.grid-card .grid-img-wrap .grid-loc img,
.LotsAuction .grid-img-wrap .grid-loc img {
    max-width: 18px;
    height: auto;
}

.LotsAuction .grid-card .grid-img-wrap .grid-loc,
.LotsAuction .grid-img-wrap .grid-loc {
    position: absolute;
    background: #fff;
    box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.2);
    height: 24px;
    padding: 4px 8px;
    border-radius: 20px;
    z-index: 99;
    width: 110px;
    top: 25px;
    left: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.LotsAuction .grid-card .gridItemAct.bid-now-wrap {
    flex-direction: column;
    row-gap: 15px;
    z-index: 999;
}

.LotsAuction .galleryPrice > span:first-child {
    color: #828282;
}

.LotsAuction .ratingLiqThree .MuiRating-root {
    font-size: 1.12rem;
}
