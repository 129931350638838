.cardSelect .cardmain .radio-item {
    display: inline-block;
    position: relative;
}

.cardSelect label.cardmain .vcRad {
    position: relative;
}

.cardSelect label.cardmain .vcRad:before {
    content: ' ';
    display: inline-block;
    position: relative;
    top: 0px;
    margin: auto;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid var(--primColor);
    background-color: transparent;
}

.checkout-row .od-box .inputPartial .customInput .MuiInputBase-input {
    font-size: 16px;
    color: #000;
    padding: 8.5px 15px;
    border: 1px solid #cfcece;
    width: 100%;
    height: 21px;
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
}

.checkout-row .od-box .inputPartial .customInput {
    margin-top: 8px;
}

.acceptTerms .customCheckbox {
    margin-top: 5px;
}

.additionalModal {
    max-height: calc(60vh - 60px);
    height: 100%;
}

.acceptTerms {
    /* margin-top: 60px !important; */
    position: relative;
}

.uploadingFile {
    border: 2px dashed #c9c9c9;
    margin-bottom: 12px;
    padding: 10px;
    width: 100%;
}

.cardSelect .cardmain input[type='radio'] {
    opacity: 0;
}

.save-search-modal {
    z-index: 20;
    margin-top: 40px;
}

.cardSelect label.cardmain .radio-item input[type='radio']:checked + .vcRad:after {
    border-radius: 11px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 1px;
    right: 5px;
    content: ' ';
    display: block;
    background: var(--primColor);
}

/* radiobox ened */

.cardSelect .cardmain {
    background: #fffaee;
    border: 1px solid var(--primColor);
    border-radius: 10px;
    padding: 12px 20px;
    line-height: 25px;
    margin: 15px auto;
    width: 100%;
}

.cardSelect .flx p {
    margin: auto 0;
}

.cardSelect .flx:nth-child(1) p {
    font-weight: 600;
    font-size: 18px;
}

.cardSelect .flx:nth-child(2) p {
    font-weight: 400;
    color: #333;
    font-size: 16px;
}

.cardSelect .flx {
    display: flex;
    justify-content: space-between;
}

.orPlcdSs img {
    width: 60px;
}

.pymtSlect .customSelect {
    margin-bottom: 10px;
}

.os-text.pdtNmLst {
    list-style: decimal;
    text-align: left;
    width: max-content;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
