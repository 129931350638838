.LiquidationThree .home {
    background: #f1f1f1;
    margin-bottom: 0;
}

.LiquidationThree .footer-main-standard {
    margin-top: 0;
}

.LiquidationThree .siteBanner {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
    border-radius: 25px;
}

.LiquidationThree .primButton button {
    background-color: var(--primColor) !important;
    width: 100%;
    color: #000 !important;
    border: 1px solid var(--primColor) !important;
}

.LiquidationThree .cartIst .MuiSvgIcon-root {
    fill: var(--primColor);
}

.LiquidationThree .mainHeader .MuiButton-root {
    color: var(--primColor) !important;
}

.homeGridProducts .LiquidationThreeBuyCard .grid-card {
    background-color: transparent;
}

.LiquidationThree .siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    border-radius: 25px;
    max-height: 500px;
    min-height: 300px;
}

.LiquidationThree .swiper-button-prev,
.LiquidationThree .swiper-button-next {
    background-color: #fff;
    color: #333333;
    height: 50px;
    width: 50px;
    top: 54%;
}

.LiquidationThree .swiper-button-prev::after,
.LiquidationThree .swiper-button-next::after {
    font-size: 17px;
    font-weight: 600;
}

.LiquidationThree .bottomSec .bottomGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 60px;
}

.LiquidationThree .partLiq h3 {
    font-size: 20px;
    font-weight: 600;
}

.LiquidationThree .partLiq img {
    display: flex;
    margin: auto;
    max-width: 260px;
    width: 100%;
}

.LiquidationThree .bottomSec {
    background-image: url(../../../assets/images/bottomSec.png);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 0px;
}

.SBA .siteBanner img {
    object-fit: scale-down;
    object-position: center;
}

.LiquidationThree .siteBanner {
    height: 450px;
    background: #fff;
}

.siteBanner-absent {
    height: 200px;
}

.siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.filters-by,
.sort-by {
    margin: 0 -5px;
}

.filters-by .filter-item,
.sort-by .sort-item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

.filters-by .custom-select {
    background: #f9f9f9
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid #ececec;
}

.sort-by .custom-select {
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid transparent;
}

.sort-item a {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.sort-item a:hover,
.sort-item a:focus,
.sort-item a.active {
    color: #ba122b;
}

.search-wrapper {
    margin-bottom: 30px;
}

.LiquidationThree .home .auctionListCnt {
    position: relative;
    padding: 30px 60px;
    border-radius: 0px;
    background-color: #f1f1f1;
    width: 100% !important;
    height: 100% !important;
}

.LiquidationThree .home .auctionListCnt .shopByCat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    gap: 10px;
}

.LiquidationThree .home .auctionListCnt .shopByCat h3 {
    margin: 0;
    color: #000;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.64px;
}

.LiquidationThree .home .auctionListCnt .shopByCat a {
    color: #222;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.367px;
}

.LiquidationThree .home .auctionListCnt .search-wrapper:first-child .auctionListTitle {
    margin-top: 0 !important;
}

.shopCategory {
    margin-bottom: 25px;
}

.gridShopContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.auctionShop {
    position: relative;
}

.auctionShop .slick-slide {
    /* margin: 0 15px; */
}

.auctionShop .slick-slide img {
    /* max-width: 230px; */
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
    margin-bottom: 30px;
}

.auctionShop .slick-slide .catgLabel {
    display: block;
    text-align: center;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    line-height: 15px;
    max-width: 230px;
}

/* .auctionShop .slick-track
  {
    width: 100% !important;
  } */
.auctionShop .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-90%);
    width: 40px;
    height: 40px;
    background-color: #f38aa270;
    color: #333;
    border-radius: 50%;
    z-index: 10;
}

.auctionShop .slick-next:before,
.auctionShop .slick-prev:before {
    color: #ffffff;
}

.auctionShop .slick-slider .slick-prev,
.trendingNow .slick-slider .slick-prev {
    left: -40px;
}

.auctionShop .slick-slider .slick-next,
.trendingNow .slick-slider .slick-next {
    right: -40px;
}

.shopCategory {
    position: relative;
    margin-top: 20px;
}

.shopCategory h3 {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    max-width: 230px;
}

.LiquidationThree .appBarWrapper .MuiAppBar-root .MuiTabs-root button .MuiTab-wrapper {
    text-transform: capitalize;
}

/* .LiquidationThree .catImg .curve
{
    position: absolute;
    background-image: url(../../../assets/images/curveImg.png);
    bottom: -2px;
    height: 43px;
    background-repeat: no-repeat;
    width: 100%;
    z-index: 2;
    background-size: cover;
} */
/* .homeProds
{
    margin: 30px 0px 0px;
} */
.homeProds .search-wrapper {
    margin-bottom: 0px;
}

.shopCategory img {
    width: 100%;
    max-width: 230px;
    height: 220px;
    object-fit: cover;
    border-radius: 12px;
}

.LiquidationThree .home .auctionListCnt .search-wrapper .viewAll {
    color: #875486;
    font-weight: 600;
    height: 45px;
    margin: 15px auto;
    display: flex;
}

.twoCart .buynow .li-timer-wrap {
    min-height: 83px;
}

.auctionListCnt .auctionListTitle::before {
    display: none;
}

.auctionListCnt .auctionListTitle::after {
    display: none;
}

.LiquidationThree .home .auctionListCnt .auctionListTitle.mobiusMart {
    margin: 0px 0px 15px 0px !important;
}

.LiquidationThree .mobiusMartCard .card-body .primButton {
    margin-top: 35px;
}

.LiquidationThree .mobiusMartCard .card-body .gaInfo label {
    display: none;
}

.auctionGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.LiquidationThree .home .siteBanner .bannerContent {
    position: absolute;
    top: 150px;
    left: 60px;
    max-width: 600px;
    width: 100%;
}

.LiquidationThree .home .siteBanner .bannerContent h2 {
    font-size: 70px;
    margin-bottom: 20px;
    color: var(--bannerTextColor);
    font-weight: 700;
    letter-spacing: 1px;
    font-family: 'Kumbh Sans' sans-serif;
}

.LiquidationThree .home .siteBanner .bannerContent p {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--bannerTextColor);
    width: 100%;
    max-width: 600px;
    min-height: 100px;
}

.LiquidationThree .home .siteBanner .bannerContent button {
    border-radius: 25px;
    min-width: 160px;
    width: max-content;
    max-width: 210px;
}

.homeActiveAuction {
    background: #efefef;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.homeActiveAuction h6 {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--primColor);
}

.homeActiveAuction h3 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #2f2f2f;
}

.homeActiveAuction .primButton button {
    width: 180px;
    border-radius: 25px;
}

.noContent {
    text-align: center;
}

.noContent h3 {
    font-size: 20px;
    color: #262626;
}

.twoCart {
    display: grid;
    grid-template-columns: auto 300px;
    gap: 15px;
}

.auctionListCnt .loadingCnt {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.auctionListCnt .loadingCnt .skeletonWrapper {
    box-shadow: none;
}

.appBarWrapper .MuiAppBar-root .MuiTabs-root button .MuiTab-wrapper,
.appBarWrapper .MuiAppBar-root .MuiTabs-root button.MuiTab-textColorInherit {
    color: #000;
    opacity: 1;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    font-family: var(--primFont);
}

.homeProds .appBarWrapper {
    border-bottom: 1px solid #b2b2b2;
}

.homeProds .tabBody {
    padding: 20px 0px;
}

.homeProds .LiquidationThreeBuyCard .grid-card {
    padding: 20px 20px 8px;
}

.LiquidationThree .siteBanner.multiple .slick-dots {
    bottom: 125px;
    background: #ffffff6e;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-radius: 100px;
    padding-top: 3px;
}

@media (min-width: 992px) and (max-width: 1366px) {
    .twoCart .gridContainer {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
}

@media (min-width: 992px) {
    .nav-sticky .nav-to-sticky {
        z-index: 11;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
    }

    .LiquidationThreeAuctions .grid-card.buynow {
        max-width: 302px;
        min-width: 302px;
    }

    .LiquidationThree .LiquidationThreeAuctions .grid-card.buynow {
        max-width: initial;
        min-width: initial;
    }

    .nav-sticky .search-category-box {
        position: fixed !important;
        border: none;
        background: #fff;
        z-index: 9;
        width: 100%;
        animation: navSticky 0.5s;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        left: 0;
        padding: 10px 15px;
        top: 50px;
    }

    .nav-sticky {
        padding-top: 124px;
    }
}

@media (max-width: 992px) {
    .twoCart {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .siteBanner h1 {
        width: max-content;
        text-align: center;
        font-size: 28px;
    }

    .siteBanner {
        height: 300px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 20%;
        left: 0;
    }

    .LiquidationThree .home .siteBanner .bannerContent {
        top: 30px;
        left: 30px;
        max-width: 80vw;
    }

    .LiquidationThree .home .siteBanner .bannerContent h2 {
        font-size: 25px;
    }

    .LiquidationThree .home .siteBanner .bannerContent p {
        font-size: 15px;
        margin-bottom: 25px;
    }

    .LiquidationThree .home .auctionListCnt {
        margin-top: -15px;
    }

    .LiquidationThree .home .auctionListCnt .auctionListTitle {
        font-size: 18px;
    }
}
@media (max-width: 650px) {
    .LiquidationThree .bottomSec .bottomGrid {
        grid-template-columns: 1fr;
    }
    .partLiq h3,
    .partLiq p {
        text-align: center;
    }
    .LiquidationThree .home .auctionListCnt .shopByCat a {
        font-size: 15px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
    .homeProds .LiquidationThreeBuyCard .grid-card {
        padding: 20px 10px 8px;
    }
    .LiquidationThree .home .auctionListCnt .shopByCat h3 {
        font-size: 20px;
    }
}
@media (max-width: 450px) {
    .LiquidationThree .mobiusMartCard .card-body .primButton {
        margin-top: 20px;
    }

    .siteBanner {
        height: 250px;
        top: 40%;
    }

    .LiquidationThree .home .auctionListCnt {
        margin-top: 0;
        padding: 5px 15px;
    }

    .siteBanner h1 {
        font-size: 18px;
        letter-spacing: 3px;
    }

    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 15%;
    }

    /* .LiquidationThree .home .auctionListCnt {
        padding: 0;
        margin-top: 30px !important;
    } */

    .LiquidationThree .home {
        background: #fff;
    }

    .LiquidationThree .home .siteBanner .bannerContent {
        top: 80px;
    }
}
