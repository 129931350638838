.otherInvoice .table tbody td .MuiFormControlLabel-root {
    margin: 0;
}

.otherInvoice .table tbody td {
    text-align: left;
    padding: 0px;
}

.LotsAuction .subLotSearch {
    right: 0;
}

.LotsAuction .table tr td.pickUpAppont .primButton button {
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
}

.LotsAuction .primButton.smallBtnCss button {
    min-height: 40px;
    height: 100%;
}
.wizardTheme .statusStyle.pending {
    color: orange;
}
.wizardTheme .statusStyle.reject {
    color: red;
}
.wizardTheme .statusStyle.approved {
    color: green;
}
.BidAuction .statusStyle {
    font-weight: 700;
    text-transform: uppercase;
}
.BidAuction .statusStyle.completedStyle {
    color: green;
}
.BidAuction .statusStyle.servingStyle {
    color: orange;
}
.LotsAuction .tableRow .aHref {
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
}

.LotsAuction .tableRow {
    display: table-row;
}

.clr-status-red {
    color: red;
}

.clr-status-green {
    color: green;
}

@media screen and (max-width: 600px) {
    table.responisveTable {
        border: 0;
    }

    table.responisveTable caption {
        font-size: 1.3em;
    }

    table.responisveTable thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table.responisveTable tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
    }

    table.responisveTable td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;
    }

    table.responisveTable td::before {
        /*
      * aria-label has no advantage, it won't be read inside a table.responisveTable
      content: attr(aria-label);
      */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table.responisveTable td:last-child {
        border-bottom: 0;
    }
}
