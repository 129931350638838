.contact-banner img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    object-position: center;
}

.contact-main-container {
    background: #dddee8;
    margin-bottom: 0;
    padding: 0 0 65px;
    min-height: calc(100vh - 150px);
}

.contact-main-container .googleMap {
    padding: 35px 25px 0px 25px;
}

.custom-info-window {
    font-family: 'Roboto', Arial, sans-serif;
    max-width: 220px;
    text-align: left;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.info-window-row {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.info-window-row:last-child {
    align-items: flex-start;
}

.info-icon {
    font-size: 18px;
    color: #666666;
    margin-right: 8px;
    flex-shrink: 0;
    line-height: 1.5;
    margin-top: 2px;
}

.info-text {
    font-size: 14px;
    color: #333333;
    line-height: 1.5;
    word-break: break-word;
    flex-grow: 1;
}

.google-map-container {
    height: 750px;
    width: 100%;
    border: 10px solid #ffffff;
    margin: 10px 0;
}

.gm-style-iw-chr {
    display: none !important;
}

.cu-margin-125 {
    margin-top: -125px;
}
.primeBids .contactform {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.contactform {
    max-width: 628px;
    width: 100%;
    border-radius: 5px;
    background: #fff;
    padding: 35px 25px 25px;
    border-bottom: 5px solid #babbce;
    min-height: 100%;
    margin: 0 auto;
}

.cu-head {
    font-weight: 600;
    font-size: 28px;
    line-height: 1;
    text-align: center;
    color: #434343;
    margin-bottom: 35px;
    text-transform: uppercase;
}

.contactform .form-control.textArea {
    height: 150px;
}

.contactform .form-control {
    height: 45px;
}

.scontact-us-wrap .btn {
    width: 160px;
}

.contactform .form-group,
.contactform .media {
    margin-bottom: 25px;
}

.max-368 {
    max-width: 368px;
}

.ru-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: start;
    color: #373737;
}

.liquidationTwo .ru-con {
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    text-align: start;
    color: #646464 !important;
    margin-bottom: 0;
    display: block;
    font-weight: 500;
    width: 100%;
    word-break: break-word;
}

.contactform .media-left .material-icons {
    margin-inline-end: 20px;
    font-size: 24px;
}

.contactform .primButton {
    width: 200px;
    margin: 0 auto;
}

.contactform .customTextArea .MuiTextField-root .MuiInputBase-root,
.contactform .customTextArea .MuiTextField-root .MuiInputBase-root textarea {
    min-height: 150px !important;
}

@media (max-width: 600px) {
    .cu-head {
        font-size: 22px;
    }
    .cu-margin-125 {
        margin-top: -185px;
        margin-bottom: 30px;
    }
    .contactform.cfRt {
        margin-top: 20px;
    }
}
