.categories-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 2%;
    justify-content: center;
}

.categories-img-box {
    width: 100%;
    height: 194px;
    border: 1px solid #edeaea;
    border-radius: 16px;
    padding: 34px 32px;
    margin: 0px 0 15px 0;
    text-align: center;
}

.categoryModal .custom-modal .modal-dialog {
    min-width: 600px;
}

.categories-img-box img {
    width: 128px;
    height: 128px;
    object-fit: contain;
}

.category-card {
    width: 32%;
    cursor: pointer;
}

.category-card h5 {
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Responsive */

@media (max-width: 767px) {
    .categories-container {
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: 0;
        column-gap: 0;
        grid-row-gap: 10px;
        row-gap: 10px;
        margin: 0 -10px;
    }

    .category-card {
        width: 33.3%;
        cursor: pointer;
        padding: 0 5px;
        text-align: center;
    }

    .categories-img-box {
        width: 100%;
        height: 130px;
        padding: 15px;
        margin: 0px 0 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .categories-img-box img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
}

@media (max-width: 600px) {
    .LotsAuction .categoryModal .custom-modal .modal-dialog {
        min-width: calc(100% - 30px);
    }
}

@media (max-width: 479px) {
    .category-card {
        width: 50%;
    }
}
