.stcPgWrpr {
    padding: 90px;
}

.faq .MuiButtonBase-root {
    background-color: #ededed;
}

@media (max-width: 600px) {
    .stcPgWrpr {
        padding: 60px 55px !important;
    }
}
