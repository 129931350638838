.LotsAuction .dbmhead {
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    color: #000;
    line-height: 1;
}

.LotsAuction .dbmhead img {
    display: inline-block;
    vertical-align: top;
}

.LotsAuction .dbmlinks {
    margin: 0 5px !important;
    padding: 0 !important;
    background: transparent;
}

.LotsAuction .cart-table .amount .MuiOutlinedInput-input {
    padding: 10.5px 14px !important;
}

.LotsAuction .dbmlinks a {
    color: #000;
    text-decoration: none;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    padding: 20px;
    border-top: 4px solid transparent;
}

.LotsAuction .customTabsInner .secButton button:hover {
    border: 1px solid #fff !important;
}

.LotsAuction .dbmlinks a.active,
.LotsAuction .dbmlinks a:hover,
.LotsAuction .dbmlinks a:focus {
    background: #fff;
    color: var(--primColor);
    text-decoration: none;
    border-top: 4px solid var(--primColor);
}

.LotsAuction .dbmlinks {
    overflow: inherit !important;
}

.LotsAuction .dashboard-wrapper {
    padding: 50px 15px;
    z-index: 1;
    position: relative;
}

.LotsAuction .mp-head {
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    color: #000;
    text-transform: uppercase;
    margin: 15px 0;
}

.LotsAuction .mp-head1 {
    font-weight: 500;
    font-size: 22px;
    text-align: left;
    color: #000;
}

.LotsAuction .profile-form-wrap form .primButton {
    max-width: 200px;
    width: max-content;
    margin-bottom: 15px;
}

.LotsAuction .dashboardMenu > header,
.LotsAuction .dashboardMenu .MuiToolbar-root {
    background: #ececec;
}

.LotsAuction .dashboardMenu.orange-header > header,
.LotsAuction .dashboardMenu.orange-header .MuiToolbar-root {
    background: var(--secColor);
    margin-top: 0;
}

.LotsAuction .dashboardMenu.orange-header .dbmlinks a {
    color: #fff;
}

.LotsAuction .dashboardMenu.orange-header .dbmlinks a.active,
.LotsAuction .dashboardMenu.orange-header .dbmlinks a:hover,
.LotsAuction .dashboardMenu.orange-header .dbmlinks a:focus {
    background: var(--primColor);
    text-decoration: none;
    border: none;
    color: #fff;
    border-radius: 8px;
}

.dashboardMenu.orange-header .dbmhead {
    color: #fff;
}

.LotsAuction .liquidationDashTabs .MuiTabs-flexContainer {
    border: none;
    width: 100%;
}

.LotsAuction .liquidationDashTabs .MuiTabs-scroller {
    text-align: center;
}

.LotsAuction .liquidationDashTabs .MuiTab-root.Mui-selected {
    background: var(--secColor);
    border-radius: 8px;
}

.LotsAuction .liquidationDashTabs .MuiTab-root {
    background: #fff;
    border-radius: 8px;
    opacity: 1;
}

.LotsAuction .liquidationDashTabs .MuiTab-root .MuiTab-wrapper {
    color: var(--primColor);
    font-weight: 600;
}

.LotsAuction .liquidationDashTabs .MuiTab-root.Mui-selected .MuiTab-wrapper {
    color: #fff;
}

.table thead th {
    background: #343a40;
}

.LotsAuction .table-responsive .pdtNmClmn {
    width: 250px;
}

.LotsAuction .loa-gray-box {
    padding: 10px 20px;
    background: #f2f2f2;
    margin-bottom: 10px;
}

@media (max-width: 991px) {
    .dbmlinks a {
        padding: 5px 10px;
        width: 100%;
    }

    .dashboardMenu {
        display: none;
    }
}

@media (max-width: 767px) {
    .LotsAuction .table-responsive .pdtNmClmn {
        width: 100%;
        padding-left: 100px;
        min-height: 75px;
    }

    .LotsAuction .mobile-table .hidden-mobile {
        display: none !important;
    }

    .LotsAuction .mobile-table td .hide-web {
        display: block;
        min-width: 150px;
    }

    .LotsAuction .mobile-table,
    .LotsAuction .mobile-table tr,
    .LotsAuction .mobile-table td,
    .LotsAuction .mobile-table tbody {
        display: block !important;
        border: 0;
        width: 100%;
    }

    .LotsAuction .mobile-table td.mb-price-td {
        display: flex !important;
        text-align: left;
    }

    .LotsAuction .table-responsive .mobile-table td.pdtNmClmn,
    .LotsAuction .mobile-table td {
        width: 100%;
        padding: 0 0 5px;
    }

    .LotsAuction .mobile-table tr {
        position: relative;
        padding: 10px 0;
        border-bottom: 1px solid #dbdbdb;
        min-height: 120px;
    }

    .LotsAuction .mobile-table tbody td.ml-data {
        position: absolute;
        left: 0;
        padding-left: 0;
        width: 90px;
        text-align: center;
    }

    .LotsAuction .mobile-table tbody td.ml-data {
        text-align: left;
    }

    .LotsAuction .mobile-table tbody td.ml-data.top-90 {
        top: 0;
        position: relative;
        display: flex !important;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .LotsAuction .mobile-table tbody td.ml-data.top-90 .customCheckbox {
        width: 20px;
    }

    .LotsAuction .mobile-table tbody td.ml-data.top-90 .customCheckbox .MuiFormControlLabel-root {
        margin: 0;
    }

    .LotsAuction .mobile-table tbody td.ml-data.top-90 .customCheckbox .MuiCheckbox-root {
        padding: 0;
    }

    .LotsAuction .mobile-table tbody td.ml-data img {
        height: 75px;
        object-fit: contain;
        border: 1px solid #ebebeb;
        border-radius: 6px;
    }

    .MuiContainer-root.dashboard-wrapper {
        padding: 25px 12px;
    }

    .MuiContainer-root.dashboard-wrapper .MuiContainer-root {
        padding: 0;
    }

    .dashboard-wrapper .MuiGrid-spacing-xs-3 > .MuiGrid-item {
        padding: 12px;
        width: 100%;
    }

    .dashboard-wrapper .db-menu-links {
        text-align: center;
    }

    .dashboard-wrapper .db-menu-links a {
        text-align: center;
        display: inline-block;
        width: 49%;
        padding: 14px 12px;
        font-size: 14px;
        vertical-align: top;
    }

    .dbmlinks.MuiListItem-gutters {
        padding: 0 12px !important;
        min-height: 40px;
    }

    .dbmlinks a.active:after {
        bottom: -10px;
    }
}

@media (max-width: 600px) {
    .LotsAuction .mobile-table tbody td {
        padding-right: 4px !important;
        padding-left: 4px !important;
    }

    .LotsAuction .table-responsive .mobile-table td.pdtNmClmn,
    .LotsAuction .mobile-table .mobile-table td {
        padding: 0 0 5px;
    }

    .flexWrap {
        flex-wrap: wrap;
    }
}

@media (max-width: 475px) {
    .mp-head {
        font-size: 16px;
    }
}

.preferences-form-wrapper .primButton button {
    width: 200px;
    margin: 0 auto;
    display: block;
}

.lq-db-table .table thead th,
.LotsAuction .dashboard-tabs .table thead th {
    background: var(--primColor);
    border: none;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    font-family: 'Plus Jakarta Sans';
    min-width: 78px;
    width: auto;
    text-align: left;
}

.LotsAuction .dashboard-tabs .table tbody td .tb-link {
    color: var(--secColor) !important;
    font-weight: 500;
}

.LotsAuction .dashboard-tabs .table tbody td .customCheckbox .MuiSvgIcon-root {
    color: var(--secColor);
}

.LotsAuction .dashboard-tabs .table tbody td {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans';
    color: #000;
}

.LotsAuction .dashboard-tabs .table .primButton button,
.LotsAuction .dashboard-tabs .table .secButton button {
    border-radius: 4px;
    font-weight: 500;
    font-family: 'Plus Jakarta Sans';
    text-transform: uppercase;
    letter-spacing: 0.0001em;
}

.LotsAuction .dashboard-tabs .nav-standard-top-area-search-input {
    background: #f0f0f0;
    border-radius: 8px;
}

.LotsAuction .dashboard-tabs .subLotSearch {
    border-radius: 0 8px 8px 0;
    right: 0;
}

.LotsAuction .schedule-pickup-btn .primButton button {
    border-radius: 4px;
    margin-left: auto;
}

.LotsAuction .schedule-pickup-btn .mt-3 {
    margin-top: 0 !important;
}

.LotsAuction table thead th.loa-item-name {
    width: 400px;
}

.LotsAuction table tbody td.common-invoice {
    color: var(--secColor);
}

@media (max-width: 767px) {
    .LotsAuction .liquidationDashTabs .MuiTabs-flexContainer {
        border: none;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 475px) {
    .LotsAuction .liquidationDashTabs .MuiTab-root {
        font-size: 12px;
    }
}
