.searchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.Auctioneer .auctioneerHomeBack {
    background: linear-gradient(
        180deg,
        rgba(240, 240, 240, 1) 0%,
        rgba(240, 240, 240, 1) 17%,
        rgba(255, 255, 255, 1) 17%
    );
    padding-top: 25px;
}

.Auctioneer .auctioneerHomeHero {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 20px;
    /* height: 380px; */
}

.Auctioneer .auctioneerHomeHero .bannerUpcomingAuc {
    height: inherit;
    border: 1px solid #e8e5e5;
    border-radius: 10px;
    max-height: 420px;
}

.Auctioneer .siteBanner {
    width: 100%;
    height: inherit;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
    border-radius: 0;
}

.Auctioneer .siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
}

.Auctioneer .search-banner-wrap {
    position: relative;
}

.Auctioneer .search-banner-wrap img.sb-logo {
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(0 0 0 / 12%);
}

.Auctioneer .search-banner-wrap img {
    width: 100%;
}
.Auctioneer .siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.Auctioneer .siteBanner .bannerContent {
    position: absolute;
    top: 160px;
    left: 60px;
}

.Auctioneer .siteBanner .bannerContent h2 {
    font-size: 34px;
    margin-bottom: 20px;
    color: var(--bannerTextColor);
    font-weight: 700;
    letter-spacing: 1px;
    max-width: 500px;
    width: 100%;
    line-height: 50px;
}

.Auctioneer .siteBanner .bannerContent p {
    font-size: 15px;
    margin-bottom: 45px;
    color: #333;
    width: 100%;
    max-width: 600px;
}

.Auctioneer .siteBanner .bannerContent .primButton button {
    background: var(--secColor);
    border: 1px solid var(--secColor);
}

.Auctioneer .siteBanner .bannerContent button {
    border-radius: 5px;
    width: max-content;
    text-transform: initial;
    font-size: 16px;
}

.Auctioneer .searchContainer .searchLt {
    position: -webkit-sticky;
    position: sticky;
    top: 115px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
    margin-bottom: 30px;
}

.Auctioneer .bannerUpcomingAuc .noCatg {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Auctioneer .bannerUpcomingAuc .noCatg img {
    width: 160px;
    height: auto;
    object-fit: contain;
}

.Auctioneer .bannerUpcomingAuc .noCatg h6 {
    font-size: 16px;
    margin: 15px 0 0;
}

.Auctioneer .bannerUpcomingAuc .catgHead {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: var(--primColor);
    border-radius: 8px;
    width: 100%;
}

.Auctioneer .bannerUpcomingAuc .catgHead h3 {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 0;
}

.Auctioneer .bannerUpcomingAuc .viewAllAuc {
    color: var(--primColor);
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 10px 22px;
    border-radius: 0;
    font-weight: 600;
}

.Auctioneer .bannerUpcomingAuc .viewAllAuc:hover {
    background: var(--primColor);
    color: #fff;
}

.Auctioneer .bannerUpcomingAuc .viewAllAuc .MuiButton-label {
    font-weight: 500;
}

.Auctioneer .bannerUpcomingAuc .catgHead button {
    min-width: auto;
    position: absolute;
    background: var(--primColor);
    top: 20px;
    right: 0;
    width: 35px;
    height: 25px;
    color: #fff;
    border-radius: 25px 0 0 25px;
}

.Auctioneer .bannerUpcomingAuc .catgHead button.expandButton {
    min-width: auto;
    position: relative;
    background: var(--primColor);
    top: unset;
    right: unset;
    width: 35px;
    height: 25px;
    color: #fff;
    border-radius: 25px 0 0 25px;
}

.Auctioneer .bannerUpcomingAuc .catgLinks {
    margin: 0;
    margin-top: 20px;
    height: 337px;
    overflow-y: scroll;
}

.Auctioneer .bannerUpcomingAuc .catgLinks .homeCatgInner {
    padding: 15px 20px;
}

.Auctioneer .searchContainer .searchRt {
    width: 100%;
    max-width: 100%;
}

.Auctioneer .searchRt .homeTitle {
    font-size: 24px;
    font-weight: 700;
    color: var(--primColor);
    margin-bottom: 25px;
    display: inline-flex;
    align-items: center;
}

.Auctioneer .searchRt .homeTitle span {
    margin-right: 5px;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.Auctioneer .searchContainer .lotResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    gap: 15px;
}

.Auctioneer
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    .accTitle,
.Auctioneer .filterPanel .MuiSvgIcon-root,
.Auctioneer .filterPanel .filterCheck .MuiTypography-root.MuiFormControlLabel-label {
    color: var(--secColor);
}

.Auctioneer
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root {
    /* border: none; */
    border-radius: 10px;
}
.Auctioneer
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.MuiAccordionSummary-root.Mui-expanded {
    border: none;
}
.Auctioneer
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    .accTitle {
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.01rem;
}

.Auctioneer
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    .accTitle,
.Auctioneer .filterPanel .MuiSvgIcon-root,
.Auctioneer .filterPanel .filterCheck .MuiTypography-root.MuiFormControlLabel-label {
    letter-spacing: 0.01rem;
}

.Auctioneer .filterPanel .MuiPaper-root.MuiAccordion-root {
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    box-shadow: none;
}

.LotsAuction
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root {
    border-radius: 100px;
    height: 40px;
}

.LotsAuction
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root
    .accTitle {
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.Auctioneer .filterPanel .filterCheck .col-12 {
    padding: 0 0 15px;
}

.Auctioneer .filterPanel .MuiAccordionDetails-root {
    padding: 0 0 1px;
    max-height: 350px;
    height: 100%;
    overflow-y: auto;
}

.Auctioneer .liquidationBuyCard.auth .mslMisc .biddingCnt button {
    border-radius: 4px !important;
    margin-left: 5px !important;
}

.Auctioneer .grid-card .hostedBy {
    min-height: 0;
}

.Auctioneer .grid-card .gcTimer {
    margin: 5px 0;
}

.filterPanel .fpTitle {
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.01rem;
}

.Auctioneer .searchContainer .searchRt .noRecordsFound {
    margin-top: 50px;
}

.Auctioneer .searchRt .name-txt {
    text-transform: capitalize;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.3px;
    font-weight: 500;
    color: #101912;
}

.Auctioneer .searchRt .liquidationBuyCard .primButton button {
    height: 45px;
    border-radius: 50px;
}

.Auctioneer .grid-card .ref-text {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.3px;
    font-weight: 400;
    color: #787878;
    min-height: 50px;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Auctioneer .pro-price {
    font-size: 28px;
    line-height: 16px;
    letter-spacing: 0.03px;
    font-weight: 600;
    margin-bottom: 5px;
}

.Auctioneer .pro-price-label {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.03px;
    font-weight: 300;
}

.Auctioneer .pro-price-label p {
    margin: 0 0 5px;
}
@media (max-width: 1145px) {
    .searchContainer .searchLt {
        max-width: 250px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }
    .searchContainer .searchLt {
        display: none;
    }

    .searchContainer .searchRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }
    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff;
        border-color: var(--primColor) !important;
    }
    .customBreadcrumbs ol {
        width: 100%;
    }
    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }

    .Auctioneer .mblStckyFilter {
        position: sticky;
        top: 45px;
        background: white;
        z-index: 999;
        display: flex;
        align-items: center;
        margin: 0px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-left: -15px !important;
        margin-right: -15px !important;
    }

    .Auctioneer .mblStckyFilter .customSelect {
        margin-bottom: 0px;
        width: 52%;
    }

    .Auctioneer .mblStckyFilter .customSelect .MuiSelect-outlined.MuiSelect-outlined {
        padding-right: 33px;
    }

    .Auctioneer .mblStckyFilter .filterButton {
        width: 24% !important;
        min-width: unset;
        height: 40px;
    }

    .Auctioneer .mblStckyFilter .filterButton.srchBtn {
        color: var(--primColor);
    }
}

@media (max-width: 992px) {
    .Auctioneer .searchContainer .lotResults.Grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        gap: 15px;
    }
    .grid-card .grid-img-wrap img {
        height: 250px;
    }
    .Auctioneer .grid-card .grid-img-wrap img {
        height: 200px;
    }
}

@media (max-width: 600px) {
    .Auctioneer .gridListToggle {
        display: none;
    }
    .Auctioneer .grid-card .ref-text {
        min-height: 45px;
        height: 100%;
    }
    .Auctioneer .searchContainer .lotResults.Grid {
        grid-template-columns: 100%;
        gap: 20px;
    }

    .Auctioneer .liquidationBuyCard .card.grid-card .grid-img-wrap img {
        height: 195px;
    }

    .Auctioneer .liquidationBuyCard .card.grid-card,
    .Auctioneer .liquidationAuctions .card.grid-card {
        padding: 5px;
    }

    .Auctioneer .grid-card .gridItemAct .favoriteCheck {
        width: 30px;
    }

    .Auctioneer .grid-card .gridItemAct .favoriteCheck label span {
        font-size: 22px;
    }

    .Auctioneer .grid-card .gridItemAct {
        display: flex;
        align-items: center;
    }

    .Auctioneer .Grid.liquidationBuyCard .primButton button .MuiButton-label {
        font-size: 12px;
    }

    .Auctioneer .grid-card .gridItemAct .cartBtn .MuiButton-label .material-icons {
        font-size: 15px;
    }

    .Auctioneer .grid-card .gridItemAct .cartBtn {
        height: 44px;
        margin: 0 5px;
        width: 41px;
        min-width: unset;
    }

    .Auctioneer .grid-card .galleryPrice {
        font-size: 14px;
    }

    .Auctioneer .grid-card .hostedBy {
        min-height: 18px;
        font-size: 13px;
    }

    .Auctioneer .grid-card .gcTimer {
        margin: 5px 0px;
    }

    .Auctioneer .mblStckyFilter .filterButton {
        width: max-content !important;
        min-width: unset;
        height: 40px;
    }

    .Auctioneer
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .MuiFormGroup-root.MuiFormGroup-row,
    .Auctioneer
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .RadioBox
        .MuiFormControlLabel-root {
        width: 100% !important;
    }

    .Auctioneer .auctioneerHomeHero {
        display: flex;
        flex-wrap: wrap-reverse;
    }

    .Auctioneer .siteBanner img {
        height: 184px;
    }

    .Auctioneer .siteBanner .bannerContent {
        top: unset;
        left: 10px;
        bottom: 10px;
    }
    .Auctioneer .siteBanner .bannerContent h2 {
        font-size: 20px;
        line-height: initial;
    }

    .Auctioneer .auctioneerHomeHero .bannerUpcomingAuc {
        width: 100%;
    }

    .Auctioneer .bannerUpcomingAuc .catgHead {
        justify-content: space-between;
    }
}

@media (max-width: 475px) {
    .toggleRespDrawer.MuiButton-root {
        padding: 3px 10px;
    }
}

@media (max-width: 500px) {
    .Auctioneer .timerWrapper .timeLabel::before {
        left: 10%;
    }
    .Auctioneer .timerWrapper .timeLabel::after {
        right: 10%;
    }
    .Auctioneer .card.grid-card .biddingCnt button .material-icons-outlined {
        display: none;
    }
}
