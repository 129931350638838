.searchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
}

.searchContainer .searchLt {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
}

.searchContainer .searchRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.Liquidation .searchMiscAct {
    margin-bottom: 10px;
}

.Liquidation .searchMiscAct .customSelect {
    margin-bottom: 0;
}

.Liquidation .searchMiscAct .customSelect:first-child {
    margin-right: 10px;
}

.Liquidation .searchMiscAct .toggleRespDrawer {
    margin-bottom: 0 !important;
    margin-left: 10px;
}

@media (max-width: 1145px) {
    .searchContainer .searchLt {
        max-width: 250px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }

    .searchContainer .searchLt {
        display: none;
    }

    .searchContainer .searchRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }

    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox,
    .responsiveFilterDrawer .filterPanel .filterCheck > div,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox > div {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .Liquidation
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .MuiFormGroup-root.MuiFormGroup-row,
    .Liquidation
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .RadioBox
        .MuiFormControlLabel-root {
        width: 100%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff;
        border-color: var(--primColor) !important;
    }

    .customBreadcrumbs ol {
        width: 100%;
    }

    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }
}

@media (max-width: 650px) {
    .Liquidation .searchMiscAct {
        margin-top: 35px !important;
    }

    .Liquidation .searchMiscAct {
        justify-content: space-between !important;
    }

    .Liquidation .searchMiscAct .customSelect {
        width: 48%;
        margin: 0;
    }

    .Liquidation .searchMiscAct .customSelect:first-child {
        margin-right: 10px;
    }

    .Liquidation .searchMiscAct .toggleRespDrawer {
        margin: 0;
        width: 100%;
        margin-top: 10px;
    }
}

@media (max-width: 420px) {
    .Liquidation .searchMiscAct .customSelect {
        width: 100%;
        margin-top: 10px;
    }

    .Liquidation .searchMiscAct .customSelect:first-child {
        margin-right: 0px;
        margin-bottom: 15px;
    }
}
