/* .nav-menu-from-store .MuiButton-text {
    right: 22px;
    top: -1px;
} */
.Collectibles .mainHeader .newHamp {
    min-width: 230px;
    list-style-type: none;
}

.Collectibles .submenuDropdown a {
    color: rgb(24, 23, 23);
}

@media (max-width: 1024px) {
    .Collectibles .mainHeader .newHamp {
        min-width: auto;
    }
}
