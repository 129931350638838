.regCnt {
    width: 100%;
    margin: 0;
    background: #efefef;
}

.Marine .regCnt {
    background-color: unset !important;
}

.regCnt > [class^='col-'] {
    padding: 0;
}

.Marine .regCnt .loginFormBox {
    background-color: unset !important;
}

.regCnt .loginFormBox {
    margin: 0;
    padding: 0;
    width: 100%;
    border-radius: 12px;
    background: #efefef;
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.regCnt .loginFormBox form {
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    margin-bottom: auto;
}

.regCnt .loginFormBox form .customCheckbox .MuiFormControlLabel-root a {
    padding-left: 5px;
}

.regCnt .loginFormBox form .primButton {
    width: 300px;
    margin: 0 auto;
}

.regCnt .nav-standard-logo {
    text-align: center;
    margin-top: auto;
    display: block;
    width: 100%;
}

.regCnt .nav-standard-logo img {
    width: 200px;
    height: 100%;
    object-fit: contain;
}

.regCnt .loginFormBox form .MuiFormControlLabel-root {
    margin: 0;
}

.regCnt .newAcc a {
    padding-left: 5px;
}

.regCnt .loginFormBox h1 {
    font-size: 30px;
    font-weight: 700;
    color: #323749;
    margin-bottom: 25px;
    text-align: center;
    margin-top: 30px;
}

.regCnt .loginFormBox h4 {
    font-size: 25px;
    font-weight: 600;
    color: #323749;
    margin-bottom: 35px;
    text-align: center;
}

.regCnt .loginFormBox .borderlessTextField {
    margin-bottom: 25px;
    max-width: 100%;
}

.regCnt .loginFormBox .fpRm .customCheckbox {
    font-size: 15px;
    color: #000;
    margin-bottom: 0;
}

.regCnt .loginFormBox .loginCta {
    margin-top: 45px;
}

.regCnt .loginFormBox .loginCta p {
    font-size: 15px;
    margin-bottom: 4px;
    color: #000;
}

.regCnt .loginFormBox .loginCta a,
.regCnt .loginFormBox .fpRm a {
    font-size: 15px;
    color: var(--primColor);
    font-weight: 500;
}

.regCnt .loginImgBox {
    background-image: url('../../../assets/images/auth-image.png');
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
}

.regCnt .loginImgBoxCincy {
    background-image: url('../../../assets/images/auth-image-cincy.png') !important;
    background-position: 15%;
    background-attachment: fixed;
}

.regCnt .loginImgBox img {
    border-radius: 5px;
    width: 145px;
    margin: 30px 30px 0 auto;
    height: 145px;
    object-fit: contain;
    background: #fff;
    padding: 0 10px;
    display: block;
}

.regCnt .loginImgBox p {
    font-size: 15px;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
}

.regCnt .termsCheck .customCheckbox {
    margin: 0 0 20px;
}

.regCnt .termsCheck .MuiFormGroup-root {
    justify-content: center;
    margin: 0;
}

.regCnt .termsCheck a {
    padding-left: 8px;
    display: inline-block;
}

.Liquidation .fpRm .forgottext {
    padding-top: 9px;
}

.Liquidation .customCheckbox .MuiFormHelperText-root,
.checkboxError .MuiFormHelperText-root,
.validationError,
.RadioBox .MuiFormHelperText-root {
    text-align: center;
    margin-bottom: 10px;
    margin-left: -0.3rem;
    color: #f44336;
    font-size: 0.75rem;
}

.Liquidation .customCheckbox .MuiFormHelperText-root {
    text-align: left;
    margin-bottom: 10px;
    margin-left: 3.6rem;
    color: #f44336;
    font-size: 0.75rem;
}

.Liquidation .loginFormBox .reg-step1 .customCheckbox .MuiFormGroup-row {
    justify-content: flex-start;
}

.full-basket-login {
    color: #875486 !important;
}

@media (max-width: 1400px) {
    .regCnt .loginImgBoxCincy {
        background-position: 12%;
    }
}

@media (max-width: 1330px) {
    .regCnt .loginImgBoxCincy {
        background-position: top left;
    }
}

@media (max-width: 991px) {
    .mw-200 {
        max-width: 200px;
        margin: 0 auto;
    }

    .regCnt .loginFormBox {
        display: block;
        height: auto;
        padding: 20px;
        z-index: 110;
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    .regCnt {
        width: 100%;
        margin: 0;
        justify-content: center;
        align-content: center;
        min-height: 100vh;
    }

    .regCnt:after {
        /* background: rgba(0, 0, 0, 0.5) url(/image/loginImg.png) no-repeat; */
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        opacity: 0.75;
    }

    .regCnt .loginFormBox h1 {
        font-size: 28px;
        margin-bottom: 25px;
        text-align: center;
    }
}
