body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.entry-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
}

.section {
    margin-bottom: 32px;
    padding: 16px;
}

/* Banner Styles */
.banner {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.responsive-banner {
    max-width: 100%;
    height: auto;
    background: none;
}

/* Grid Layout */
.columns-shortcode {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    align-items: start;
    width: 100%;
}
.section-two {
    padding: 20px 0px;
}
.map-mrgn {
    margin-left: 16%;
}
@media (max-width: 768px) {
    .columns-shortcode {
        grid-template-columns: 1fr;
        gap: 24px;
    }
    .map-mrgn {
        margin-left: unset;
    }
}

/* Map Styling */
.map-container iframe {
    width: 100%;
    height: 300px;
    border: 0;
    border-radius: 8px;
}

/* Location Details */
.location-details h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
    text-align: start;
}

.location-details p {
    margin: 4px 0;
    font-size: 16px;
    text-align: start;
}

.location-details h3 {
    font-size: 20px;
    margin-top: 12px;
    text-align: start;
}

/* Center Alignment */
.center-xs {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
