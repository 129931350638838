.testMCard {
    background: #ffffff;
    max-width: calc(100% - 50px);
    margin: auto;
    padding: 15px 22px;
    border-radius: 10px;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    box-shadow: 0px 1px 10px -2px #00000026;
    margin-top: 40px;
    transition: ease-in-out 0.3s;
    height: 530px;
}

.testMCard .topCnt {
    align-items: center;
}

.testMCard .topCnt .profileImg {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    overflow: hidden;
    max-width: 100%;
    margin: auto;
    margin-bottom: 15px;
}

.testMCard .reviewUserTimeSpan span:nth-child(2) {
    color: #ffcc32;
}

.testMCard .userContent {
    margin-top: 15px;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
    color: #565656;
    text-overflow: ellipsis;
    min-height: 150px;
    -webkit-line-clamp: 10;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    line-height: 25px;
}

.testMCard .reviewUserTimeSpan {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
}

.testMCard .topCnt .googleReview {
    width: 50px;
    height: 50px;
    object-fit: scale-down;
    display: block;
    margin: 15px auto;
}
.testMCard .topCnt img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.testMCard h5 {
    margin-top: 34px;
    color: var(--secColor);
    font-size: 16px;
    font-weight: 400;
    min-height: 45px;
    line-height: 26px;
}

.testMCard .rvwDscrption {
    font-size: 15px;
    line-height: 26px;
    margin-top: 18px;
    font-weight: 500;
    height: 130px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
}

.testMCard .rvwrCnt {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -87px;
}

.testMCard .rvwrCnt .mrtIcnWrpr {
    border-radius: 100px;
    background: white;
    box-shadow: 0 3px 6px #00000026;
    width: 78px;
    height: 78px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.testMCard .rvwrCnt .material-icons {
    font-size: 92px;
    color: #d6d6d6;
    position: absolute;
    top: -7px;
    left: -7px;
}

.testMCard .rvwrCnt p {
    font-weight: 600;
    margin-top: 5px;
}

.testMCard .material-icons.active {
    color: #ffcc32;
}

.tstSlickSlider .slick-slide > div {
    padding-left: 10px;
    padding-right: 10px;
}

.tstSlickSlider .slick-slide.slick-active.slick-center > div {
    padding-left: 0;
    margin: 0 -25px;
    padding-right: 0;
    z-index: 9999;
    overflow: visible;
    position: relative;
}

.tstSlickSlider .slick-slide.slick-active.slick-center .testMCard {
    margin-top: 0;
    background: var(--secColor);
    color: white;
}

.tstSlickSlider .slick-slide.slick-active.slick-center .testMCard h5 {
    color: white;
}

.tstSlickSlider .slick-slide.slick-active.slick-center .testMCard .topCnt img {
    filter: brightness(16.5);
}

.tstSlickSlider .slick-slide.slick-active.slick-center .testMCard .rvwrCnt .material-icons {
    color: #bcbcbc;
}

.tstSlickSlider .slick-slide.slick-active.slick-center .testMCard .rvwrCnt p {
    color: black;
}

@media (max-width: 600px) {
    .testMCard .topCnt p {
        text-align: right;
    }
    .testMCard {
        max-width: 100%;
    }
}
