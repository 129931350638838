.filterPanel .fpTitle {
    font-size: 18px;
    color: #231f20;
    font-weight: 700;
    text-align: start;
    margin-bottom: 5px;
}

.MultiSeller .filterPanel .fpTitle {
    font-size: 18px;
    font-weight: 500;
}

.filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root {
    border: 1px solid #e4e4e4;
    min-height: 40px;
    height: 45px;
    border-radius: 5px;
}

.liquidationTwo.emursMck
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root {
    border: 1px solid #e4e4e4;
    min-height: 40px;
    height: 45px;
    border-radius: 15px;
    background-color: var(--secColor);
}

.liquidationTwo.emursMck .filterPanel .MuiPaper-root.MuiAccordion-root {
    border-radius: 15px;
    background-color: var(--primColor);
}

.liquidationTwo.emursMck .filterPanel .MuiPaper-root.MuiAccordion-root .MuiFormControlLabel-label {
    color: #fff !important;
}

.filterPanel .MuiPaper-root.MuiAccordion-root {
    border: none;
    margin-bottom: 10px;
}

/* .LiquidationThree .filterPanel.visible
{
    display: block;
}
.LiquidationThree .filterPanel
{
    display: none;
} */
.filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root
    .accTitle {
    color: #333031;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    position: relative;
}

.BidAuction
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root
    .accTitle {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    position: relative;
    text-transform: uppercase;
}

.MultiSeller
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root
    .accTitle {
    font-weight: 400;
}

.filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    .accTitle {
    color: var(--primColor);
}

.liquidationTwo.emursMck
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root
    .accTitle {
    color: var(--primColor);
}

.filterPanel
    .filterAcc
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon {
    color: var(--primColor);
}

.liquidationTwo.emursMck
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root
    .MuiSvgIcon-root {
    color: #ffffff;
}

.filterTitleCnt {
    margin-bottom: 15px;
}

.filterPanel h4 {
    margin-bottom: 0;
}

.filterPanel .filterClear {
    text-transform: none;
    color: #8e8e8e;
    font-size: 13px;
}

.filterPanel .filterClear .material-icons {
    padding-inline-end: 5px;
    font-size: 20px;
}

.filterPanel .MuiAccordionDetails-root {
    padding: 10px 5px;
    max-height: 350px;
    height: 100%;
    overflow-y: auto;
}

.liquidationTwo.wizardTheme .MuiAccordionDetails-root {
    max-height: 252px;
    padding: 0 5px;
}

.liquidationTwo.wizardTheme
    .filterCheck
    .MuiFormControlLabel-root
    .MuiButtonBase-root
    .MuiIconButton-label {
    color: #aeaeae;
}

.liquidationTwo.wizardTheme
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root {
    border: none;
}

.liquidationTwo.wizardTheme .MuiSlider-root {
    width: 100%;
    max-width: none;
    flex: 1;
    color: var(--secColor);
}

.liquidationTwo.wizardTheme .MuiSlider-thumb {
    background-color: #fff;
    border: 3px solid var(--secColor);
    height: 16px;
    width: 16px;
    margin-top: -7px;
}

.liquidationTwo.wizardTheme .filterCheck > div:has(.MuiSlider-root) {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    margin: 0;
}
.liquidationTwo.wizardTheme .MuiAccordionDetails-root:has(.MuiSlider-root) {
    overflow: unset;
}

.liquidationTwo.wizardTheme
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    .accTitle {
    color: black;
    font-size: 18px;
    font-weight: 700;
}

.BidAuction .filterPanel .MuiAccordionDetails-root.locationAccord {
    display: grid;
}

.BidAuction .filterPanel .MuiAccordionDetails-root {
    box-shadow: 0px 2px 10px 0px #00000021;
}

.LotsAuction .filterPanel .MuiAccordionDetails-root {
    padding: 10px 0;
}

.filterPanel .MuiAccordionDetails-root::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
}

.filterPanel .MuiAccordionDetails-root::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.filterPanel .MuiAccordionDetails-root:hover::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
}

.filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row {
    width: 100%;
}

.filterPanel .filterCheck .customCheckbox {
    width: 100%;
}

.LotsAuction .filterPanel .filterAcc .MuiCollapse-wrapperInner {
    margin-top: 15px;
}

.BidAuction
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root
    .MuiSvgIcon-root {
    color: #fff;
}

.BidAuction .SelectedlocationInfo p {
    font-size: 10px;
    color: #fff;
    font-weight: 500;
    margin: 0;
}

.BidAuction .SelectedlocationInfo h4 {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
}

.BidAuction .SelectedlocationInfo {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
    align-items: start;
    background-color: #013748;
    padding: 10px;
    width: 100%;
    color: #fff;
}

.BidAuction
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root {
    background-color: #013748;
    border-radius: 10px;
}

.BidAuction
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

/* .LotsAuction .filterPanel .filterCheck .customCheckbox .MuiFormControlLabel-label {
    padding: 2px 15px;
}
.LotsAuction .filterPanel .filterCheck .customCheckbox .MuiListItem-gutters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding-bottom: 5px;
    background: transparent;
}

.LotsAuction .filterPanel .filterCheck .customCheckbox .MuiListItem-gutters:focus {
    background: transparent;
}

.LotsAuction .filterPanel .filterCheck .customCheckbox .MuiCheckbox-root {
    visibility: hidden;
    width: 100%;
    position: absolute;
} */

.filterPanel .filterCheck .MuiFormControlLabel-root,
.filterPanel .filterCheck .MuiTypography-root.MuiFormControlLabel-label,
.filterPanel .filterCheck .MuiListItem-button {
    width: 100%;
    margin: 0;
}

.LotsAuction
    .filterPanel
    .filterCheck
    .customCheckbox
    .MuiCheckbox-root.Mui-checked
    ~ .MuiFormControlLabel-label {
    color: var(--primColor);
}

.LotsAuction .auction-filter .filterPanel .filterCheck .customCheckbox .MuiCheckbox-root {
    padding: 9px 5px;
}

.filterPanel .filterCheck .MuiListItem-button {
    padding: 0;
    font-size: 15px;
}

.filterCheck .MuiTypography-root.MuiFormControlLabel-label {
    font-size: 15px;
}

.LotsAuction .filterPanel .filterCheck .MuiListItem-button {
    font-size: 14px;
}

.filterPanel .filterCheck .MuiFormHelperText-root {
    display: none;
}

.RadioBox .MuiListItem-button {
    padding: 0;
}

.RadioBox .MuiAccordionDetails-root {
    padding-top: 0;
}

.filterPanelSkeleton {
    /* height: 60vh; */
    margin-bottom: 30px;
}

.filterPanel .priceSlider {
    width: 100%;
    margin-top: 20px;
}

.filterPanel .priceSlider .MuiSlider-root {
    color: var(--primColor) !important;
}

.filterPanel .priceSlider h5 {
    font-size: 15px;
    text-align: left;
    color: #2b2b2b;
    font-weight: 600;
}

.filterPanel .priceSlider h5 span {
    padding: 0 5px;
}

.filterPanel .filterCheck {
    width: 100%;
}

/* HeavyEquipment css */

.HeavyEquipment
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root
    .accTitle:after {
    position: absolute;
    display: block;
    content: '';
    top: auto;
    bottom: -4px;
    right: auto;
    left: 0;
    width: 37px;
    background: var(--primColor);
    height: 2px;
}

.HeavyEquipment
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
}

.HeavyEquipment .filterPanel .filterCheck .col-12 {
    transform: translateX(-25px);
}

.HeavyEquipment
    .filterPanel
    .filterAcc
    .MuiPaper-root.MuiAccordion-root
    .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded
    + .MuiCollapse-root {
    border: 1px solid #e4e4e4;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 0;
}

/* responsive css */

@media (max-width: 500px) {
    .filterPanel
        .filterAcc
        .MuiPaper-root.MuiAccordion-root
        .MuiButtonBase-root.MuiAccordionSummary-root
        .accTitle {
        font-size: 14px;
    }

    .filterPanel .filterCheck .MuiListItem-button {
        font-size: 14px;
    }

    .filterCheck .MuiTypography-root.MuiFormControlLabel-label {
        font-size: 14px;
    }

    .MultiSeller .filterPanel .fpTitle {
        font-size: 15px;
    }
}
