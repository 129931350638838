.grid-img-wrap img.co-img {
    width: 198px;
    height: 198px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin-right: 25px;
}

.checkout-row .grid-img-wrap img.co-img {
    object-fit: contain;
    background: rgb(250, 250, 250);
}

.co-box {
    padding: 15px;
    background: #f5f5f5;
    /* height: 198px; */
    border-radius: 10px;
}
.Liquidation .cob-top .MuiListItem-gutters {
    padding-left: 0px !important;
}
.co-box-two {
    border-radius: 10px;
    background: #fff;
    border: 2px dashed #c9c9c9;
    padding: 25px 20px;
}
.co-box-two .cob-top {
    padding-right: 0px;
}
.co-box-two .cob-top .co-label {
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
    display: block;
}

.co-box-two .cob-top i {
    font-size: 12px;
}
.co-box-two input[type='text'] {
    font-size: 16px;
    color: #000;
    padding: 8.5px 15px;
    border: 1px solid #cfcece;
    width: 100%;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}
.cob-top {
    position: relative;
    padding-right: 80px;
    margin-bottom: 10px;
}

.cob-top .co-action {
    position: absolute;
    top: 0;
    right: 0;
}

.LotsAuction .cob-top {
    padding-right: 0;
}

.co-action a {
    display: inline-block;
    padding: 0 10px;
    vertical-align: middle;
    margin: 0 1px;
    color: #5d646c !important;
    cursor: pointer;
    font-size: 18px;
}

.co-breadcrumb a {
    color: #2c2c37;
}

.co-breadcrumb a:hover,
.co-breadcrumb a:focus,
.co-action a:hover,
.co-action a:focus {
    color: var(--primColor);
    cursor: pointer;
}

.co-label {
    font-size: 16px;
    text-align: left;
    color: #5d646c;
    margin-bottom: 2px;
}

.co-text {
    font-size: 18px;
    text-align: left;
    color: #000;
}

.co-breadcrumb {
    color: #afafaf;
    font-size: 15px;
    text-align: left;
    margin-bottom: 25px;
}

.od-box {
    border-radius: 10px;
    background: #fff;
    border: 2px dashed #c9c9c9;
    padding: 25px 20px;
}

.od-head {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 1rem;
    line-height: 28px;
}

.od-con {
    font-size: 18px;
    text-align: left;
    color: #000;
    margin-bottom: 15px;
}

.od-con1 {
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    color: #000;
    margin-bottom: 15px;
}

.grn-txt {
    color: #12ba55;
}

.cinput {
    position: relative;
    margin: 2rem auto 0;
}

.cinput .form-control {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    background: #f2f2f2;
    padding: 10px 150px 10px 15px;
}

.cinput .cp-btn {
    padding: 10px;
    width: 135px;
    height: 45px;
    border-radius: 0px 5px 5px 0px;
    background: #5d646c;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
}

.optional-txt {
    font-size: 12px;
    line-height: 25px;
    text-align: left;
    color: #c7c7c7;
    margin: 5px 0;
}

.od-box .primary-btn {
    width: 100%;
    height: 50px;
    margin: 25px auto 0;
}

.close-coupon {
    position: absolute;
    padding: 5px;
    top: 5px;
    right: 135px;
    cursor: pointer;
}
.grid-img-wrap .favoriteCheck {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.LotsAuction .grid-img-wrap .favoriteCheck {
    top: inherit;
    bottom: 10px;
    right: 10px;
    z-index: 1;
    float: none;
    margin: 0 !important;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.2);
}

.LotsAuction .liquidationBuyCard .favoriteCheck label {
    margin: 0;
    color: #ff3838;
    height: 24px;
    width: 24px;
}

.MultiSeller .favoriteCheck {
    width: 45px;
    border-radius: 50% !important;
}

.liquidationTwo .favoriteCheck {
    background: transparent;
}

.lclPckupLctn {
    padding-left: 32px;
}

.Auctioneer .grid-img-wrap .favoriteCheck {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

.chkShpngMsg input {
    height: 40px;
    border-radius: 5px !important;
}

.co-text .material-icons {
    line-height: 0.4;
}

@media (min-width: 992px) {
    .checkout-row .col-md-8 {
        -ms-flex: 0 0 calc(100% - 450px);
        flex: 0 0 calc(100% - 450px);
        max-width: calc(100% - 450px);
    }

    .checkout-row .col-md-4 {
        -ms-flex: 0 0 450px;
        flex: 0 0 450px;
        max-width: 450px;
    }
}

@media (max-width: 767px) {
    .grid-img-wrap img.co-img {
        width: 100%;
        height: 240px;
        margin: 0 0 10px;
        object-position: center;
        border-radius: 5px;
        object-fit: cover !important;
    }

    .co-box {
        height: auto;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    .LotsAuction .co-label,
    .LotsAuction .co-text {
        font-size: 14px;
        white-space: normal;
        width: 48%;
    }

    .LotsAuction .cob-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1.5;
    }
}

@media (max-width: 479px) {
    .checkout-row .media,
    .checkout-row .chkShpngMsg {
        margin-top: 20px;
        flex-wrap: wrap;
    }

    .checkout-row .media-left,
    .checkout-row .input-group-btn,
    .checkout-row .input-group-btn .pink-btn {
        width: 100%;
    }

    .checkout-row .chkShpngMsg input {
        width: 100%;
        margin: 0 0 10px !important;
    }
}
