.liquidationTwo .vcaforgotPassword {
    width: 100%;
    margin: 0;
    height: 100%;
    min-height: calc(100vh - 400px);
}

.liquidationTwo .vcaforgotPassword .forgotPasswordFormBox {
    margin: 0;
    padding: 0;
    width: 100%;
    border-radius: 12px;
    background: #fff;
    height: auto;
    display: flex;
    align-items: center;
}

.liquidationTwo.emursMck .forgotPasswordFormBox {
    background: url(../../../assets/images/bgEmursWrapper.svg);
    background-repeat: repeat;
    background-position: center;
    border-radius: 0;
}

.liquidationTwo.emursMck .forgotPasswordFormBox .bda-input label {
    color: var(--secColor);
    background-color: var(--primColor);
}

.liquidationTwo.emursMck .forgotPasswordFormBox .bda-input input {
    color: var(--secColor);
    border-color: var(--secColor);
}

.liquidationTwo.emursMck .forgotPasswordFormBox .bda-input input:focus {
    border-color: var(--secColor);
}

.liquidationTwo.emursMck .forgotPasswordFormBox .bda-input input::placeholder {
    color: var(--secColor);
}

.liquidationTwo.emursMck .forgotPasswordFormBox form {
    background-color: var(--primColor);
}

.liquidationTwo .vcaforgotPassword form {
    max-width: 385px;
    margin: 0 auto;
    width: 100%;
}

.liquidationTwo .vcaforgotPassword .forgotPasswordFormBox h1 {
    font-size: 45px;
    font-weight: 700;
    color: #000;
    margin-bottom: 45px;
    width: 50%;
    line-height: 60px;
}

.liquidationTwo .vcaforgotPassword .forgotPasswordFormBox > img {
    display: none !important;
}

.liquidationTwo .vcaforgotPassword .forgotPasswordFormBox .borderlessTextField {
    margin-bottom: 25px;
    max-width: 100%;
}

.liquidationTwo .vcaforgotPassword .forgotPasswordFormBox .forgotPasswordCta {
    margin-top: 30px;
}

.liquidationTwo .vcaforgotPassword button {
    width: 100%;
}

.liquidationTwo .vcaforgotPassword .forgotPasswordImgBox {
    /* background-image: url('./image/forgot.png'); */
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.liquidationTwo .vcaforgotPassword .forgotPasswordImgBox img {
    border-radius: 5px;
    width: 145px;
    margin: 30px 30px 0 auto;
    height: 145px;
    object-fit: contain;
    background: #fff;
    padding: 0 10px;
}

.liquidationTwo .vcaforgotPassword .forgotPasswordImgBox p {
    font-size: 15px;
    color: #000;
    text-align: center;
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    .mw-200 {
        max-width: 200px;
        margin: 0 auto;
    }

    .liquidationTwo .vcaforgotPassword .forgotPasswordFormBox {
        display: block;
        height: auto;
        padding: 20px;
        z-index: 110;
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    .liquidationTwo .vcaforgotPassword {
        width: 100%;
        margin: 0;
        justify-content: center;
        align-content: center;
        min-height: 100vh;
    }

    .liquidationTwo .vcaforgotPassword:after {
        /* background: rgba(0, 0, 0, 0.5) url(/image/loginImg.png) no-repeat; */
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        opacity: 0.75;
    }

    .liquidationTwo .vcaforgotPassword .forgotPasswordFormBox h1 {
        font-size: 28px;
        margin-bottom: 25px;
        width: 100%;
    }
}

@media (max-width: 500px) {
    .liquidationTwo .vcaLogin .loginFormBox form {
        margin-top: 0 !important;
    }

    .liquidationTwo .vcaforgotPassword {
        min-height: 100% !important;
    }
}
