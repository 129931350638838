.LotsAuction .search.siteBanner {
    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0;
}

.LotsAuction .search.siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.LotsAuction .search.siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.LotsAuction .twoCart .liquidationAuctions .buynow .card-body .gaInfo .listDesc {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 7px;
    min-height: 48px;
}

.LotsAuction .twoCart .liquidationAuctions .buynow .card-body .gaInfo .listDesc * {
    margin: 0;
}

.LotsAuction .filters-by,
.LotsAuction .sort-by {
    margin: 0 -5px;
}

.LotsAuction .filters-by .filter-item,
.LotsAuction .sort-by .sort-item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

.LotsAuction .filters-by .custom-select {
    background: #f9f9f9
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid #ececec;
}

.LotsAuction .sort-by .custom-select {
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid transparent;
}

.LotsAuction .sort-item a {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.LotsAuction .sort-item a:hover,
.LotsAuction .sort-item a:focus,
.LotsAuction .sort-item a.active {
    color: #ba122b;
}

.LotsAuction .search-wrapper {
    margin-bottom: 30px;
}

.LotsAuction .auctionListCnt {
    position: relative;
    margin-top: 0;
    background: #fff;
    padding: 30px;
    border-radius: 20px;
}

.LotsAuction .auctionListCnt .auctionListTitle {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #656565;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
}

.LotsAuction .auctionListCnt .auctionListTitle::before {
    position: absolute;
    content: '';
    width: 30%;
    height: 1px;
    background: #dedede;
    left: 10%;
    top: 10px;
}

.LotsAuction .auctionListCnt .auctionListTitle::after {
    position: absolute;
    content: '';
    width: 30%;
    height: 1px;
    background: #dedede;
    right: 10%;
    top: 10px;
}

.LotsAuction .auctionGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.LotsAuction .gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.LotsAuction .auctionsMiscFilter {
    display: flex;
    width: calc(100% - 120px);
}

.LotsAuction .auction-wrapper .auctionsMiscFilter {
    min-width: 80px;
}

.LotsAuction .auctionsMiscFilter .customSelect {
    margin-left: 25px;
    margin-bottom: 0;
}

.LotsAuction .auctionsMiscFilter .gridListToggle button {
    min-width: initial;
    color: #999;
    opacity: 0.5;
}

.LotsAuction .auctionsMiscFilter .gridListToggle button.active {
    color: #000;
    opacity: 1;
}

.LotsAuction .auctionsSearch .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.LotsAuction .auctionListTitle {
    font-size: 24px;
    color: #414141;
    margin-bottom: 20px;
    font-weight: 700;
}

.LotsAuction .auctionsSearch .grid-card {
    box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 22%);
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #f2f2f2;
    background-color: #ffffff;
}

.LotsAuction .auctionsSearch .grid-card .grid-img-wrap {
    margin: -10px -10px 0;
    padding: 10px;
    grid-template-columns: 48% 1fr;
    grid-gap: 15px;
    gap: 15px;
    display: grid;
}

.LotsAuction .auctionsSearch .grid-card .grid-img-wrap img {
    height: 150px;
    object-fit: scale-down;
}

.LotsAuction .auctionsSearch .grid-card .grid-img-wrap_single {
    grid-template-columns: 100% !important;
}

.LotsAuction .auctionsSearch .grid-card .grid-img-wrap_single img {
    height: 315px !important;
}

.LotsAuction .auctions-header .container.auctionsSearch {
    margin-bottom: 0;
    min-height: auto;
}

.LotsAuction .auctions-header .auctionsMiscFilter .gridListToggle button {
    color: #000;
}

.LotsAuction .auctions-header .auctionsMiscFilter .gridListToggle button.active {
    color: #fff;
    background: var(--primColor);
}

.auctions-header .customSelect .MuiFormControl-root.MuiTextField-root {
    background: #fff;
    border-radius: 5px;
}

.auctions-header .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -18px) scale(0.75);
    color: #fff;
}

.LotsAuction .table.bidHistory thead th:last-child,
.LotsAuction .table.bidHistory tbody td:last-child {
    text-align: left;
}

.LotsAuction .table.bidHistory thead th {
    background: #fff;
    color: #333;
}

.LotsAuction .table-striped.bidHistory tbody tr:nth-of-type(odd) {
    background-color: #f5f5f5;
}

.LotsAuction .table-striped.bidHistory tbody tr td {
    border: none;
}

.LotsAuction .bids-row address {
    font-size: 14px;
}

.LotsAuction .auctions-header {
    background: #0000000a;
    padding: 15px 0;
    margin: 0 auto 20px;
}

.LotsAuction .auctions-header .auctionListTitle,
.LotsAuction .auctions-header .buyNowTitle {
    font-size: 24px;
    color: #000;
    margin-bottom: 0;
    font-weight: 700;
}

.LotsAuction .auctions-header .container.auctionsSearch {
    margin-bottom: 0;
    min-height: auto;
}

/* .LotsAuction .auctions-header .auctionsMiscFilter .gridListToggle button {
    color: #fff;
} */

.LotsAuction .auctions-header .auctionsMiscFilter .gridListToggle button.active {
    color: #fff;
    background: var(--primColor);
}

.LotsAuction .auctions-header .customSelect .MuiFormControl-root.MuiTextField-root {
    background: #fff;
    border-radius: 5px;
}

.LotsAuction .auctions-header .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -18px) scale(0.75);
    color: #fff;
}

@media (min-width: 992px) {
    .LotsAuction .nav-sticky .nav-to-sticky {
        z-index: 11;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
    }

    .LotsAuction .nav-sticky .search-category-box {
        position: fixed !important;
        border: none;
        background: #fff;
        z-index: 9;
        width: 100%;
        animation: navSticky 0.5s;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        left: 0;
        padding: 10px 15px;
        top: 50px;
    }

    .LotsAuction .nav-sticky {
        padding-top: 124px;
    }
}

@media (max-width: 991px) {
    .LotsAuction .gridListToggle {
        position: relative;
        right: 0;
        top: 0;
        left: auto;
        display: flex;
    }
}

@media (max-width: 767px) {
    .LotsAuction .auctionsSearch .auctionListTitle {
        font-size: 18px;
    }

    .LotsAuction .auctionsMiscFilter {
        width: calc(100% - 90px);
    }

    .LotsAuction .auctionsMiscFilter .customSelect {
        margin-left: 5px;
        margin-right: 0;
    }

    .LotsAuction .auctionsSearch .searchResults.Grid {
        grid-template-columns: 48% 1fr;
    }

    .LotsAuction .auctionsMiscFilter .MuiSelect-outlined.MuiSelect-outlined {
        padding: 10px 22px 10px 10px;
        font-size: 11px;
        line-height: 1;
    }

    .LotsAuction .auctionsMiscFilter .material-icons {
        font-size: 20px;
    }

    .LotsAuction .auctionsSearch .grid-card .grid-img-wrap img {
        height: 100px;
    }

    .LotsAuction .siteBanner h1 {
        width: max-content;
        text-align: center;
        font-size: 28px;
    }

    .LotsAuction .siteBanner {
        height: 300px;
    }

    .LotsAuction .auctionListCnt .auctionListTitle::before,
    .LotsAuction .auctionListCnt .auctionListTitle::after {
        width: 20%;
        left: 0;
    }
}

@media (max-width: 479px) {
    .LotsAuction .auctionsMiscFilter {
        width: 100%;
        margin: 10px auto 0;
        position: relative;
    }

    .LotsAuction .siteBanner {
        height: 300px;
        top: 40%;
    }

    .LotsAuction .siteBanner h1,
    .LotsAuction .auctions-header .auctionListTitle,
    .LotsAuction .auctions-header .buyNowTitle {
        font-size: 18px;
        letter-spacing: 1px;
    }

    .LotsAuction .auctionListCnt .auctionListTitle::before,
    .LotsAuction .auctionListCnt .auctionListTitle::after {
        width: 15%;
    }

    .LotsAuction .auctionsMiscFilter .gridListToggle {
        position: absolute;
        top: -38px;
    }

    .LotsAuction .auctionsSearch .searchResults.Grid {
        grid-template-columns: 1fr;
    }
}
