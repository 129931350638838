.location_hours_card_container .location_hours_card {
    /* border-radius: 25px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: #0003 0 3px 1px -2px, #00000024 0 2px, #0000001f 0 1px 5px;
    width: 100%; */
    color: black;
}

.location_hours_card_container .location_card_content {
    box-sizing: border-box;
    border: 1px solid #ffffff;
}
.location_hours_card_container .location_card_content p {
    margin-bottom: 0;
    margin-top: 8px;
    min-height: 45px;
}
.location_hours_card_container .google_map_container {
    position: relative;
    overflow: hidden;
    height: 300px;
    width: 100%;
    margin: auto;
    border-radius: inherit;
}

.location_hours_card_container .location_card_content h4 {
    margin: 20px 0 0;
    font-size: 1.3rem;
}

.location_hours_card_container .location_hours_card_box .get_direction_btn {
    padding: 6px 16px;
    height: auto;
    font-size: 1rem;
    line-height: 1.75;
    min-width: 64px;
    display: flex;
    align-items: center;
    gap: 8px;
    background: linear-gradient(104.02deg, #ed213a 8.61%, #93291e 55.61%);
    margin: 20px 0;
    border-radius: 100px;
    width: 100%;
    color: #ffffff;
    border: transparent;
    font-weight: 700;
    justify-content: center;
    text-transform: uppercase;
    white-space: nowrap;
}
.location_hours_card_container .location_card_content p {
    margin: 0;
}
.location_hours_card_container .location_hours_card_box .hours_list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.location_hours_card_container .location_hours_card_box .hours_list .hours_title {
    font-weight: 800;
}
.location_hours_card_box .primButton {
    margin: 10px 0 15px;
}

.hours_list_items {
    width: 100%;
}

.hours_list_items > div {
    display: flex;
    margin-top: 5px;
}

.hours_list_items > div span:first-child {
    padding-right: 10px;
    min-width: 150px;
}

@media (max-width: 767px) {
    .location_hours_card_container {
        grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
        gap: 15px;
    }
    .hours_list_items > div span:first-child {
        min-width: 110px;
    }
}

@media (max-width: 600px) {
    .location_hours_card_container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 440px) {
    .hours_list_items,
    .location_hours_card_container .location_card_content p,
    .location_hours_card_container .location_hours_card_box .hours_list .hours_title {
        font-size: 14px;
    }

    .location_hours_card_container .location_card_content h4 {
        font-size: 18px;
    }
    .location_hours_card_container .location_hours_card {
        border-radius: 20px;
    }
}
